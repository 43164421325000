// @ts-nocheck
import React, { useState } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext';

const pageJSON = {
    headerTitle: [
        {
            title: 'İletişim formu',
            languageCode: 'tr'
        },
        {
            title: 'Contact form',
            languageCode: 'en'
        }
    ],
    firstAndLastName: [
        {
            title: 'Adınız soyadınız',
            languageCode: 'tr'
        },
        {
            title: 'Full Name',
            languageCode: 'en'
        }
    ],
    phoneNumber: [
        {
            title: 'Telefon Numaranız',
            languageCode: 'tr'
        },
        {
            title: 'Phone Number',
            languageCode: 'en'
        }
    ],
    email: [
        {
            title: 'E-posta adresiniz',
            languageCode: 'tr'
        },
        {
            title: 'Email',
            languageCode: 'en'
        }
    ],
    description: [
        {
            title: 'Açıklama',
            languageCode: 'tr'
        },
        {
            title: 'Description',
            languageCode: 'en'
        }
    ],
    send: [
        {
            title: "Gönder",
            languageCode: 'tr'
        },
        {
            title: 'Send',
            languageCode: 'en'
        }
    ],
};

const ContactModal = ({ openModal, setOpenModal }) => {
    const { language } = useLanguage();
    const [isWorking, setIsWorking] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);

    const [formData, setFormData] = useState({
        fullName: '',
        phoneNumber: '',
        email: '',
        description: '',
    });

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsWorking(true);

        try {
            const baseUrl = process.env.REACT_APP_API_ADDRESS;

            const response = await fetch(baseUrl + '/api/SendIndiviualEmail', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': '*/*',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify(formData),
            });

            setIsWorking(false);
            if (response.ok) {
                setIsSuccess(true);
            } else {
                setIsSuccess(false);
            }
        } catch (error) {
            setIsSuccess(false);
            setIsWorking(false);
        }
    };

    return (
        <div modal="career" style={{
            position: "fixed",
            top: "0",
            left: "0",
            width: "100%",
            height: "100%"
        }}
            status={`${!openModal ? "hide" : "show"}`}
        >
            <div modal-content="">
                <span onClick={() => setOpenModal(false)} modal-close=""><i icon="mat-symbol xl">close</i></span>

                <h4>{pageJSON.headerTitle.find(x => x.languageCode === language)?.title}</h4>

               

                <form form="contact" onSubmit={handleSubmit}>
                    <label form-item="">
                        <span>{pageJSON.firstAndLastName.find(x => x.languageCode === language)?.title}</span>
                        <input
                            type="text"
                            name="fullName"
                            value={formData.fullName}
                            onChange={handleChange}
                            required
                        />
                    </label>

                    <label form-item="mt-2">
                        <span>{pageJSON.phoneNumber.find(x => x.languageCode === language)?.title}</span>
                        <input
                            type="tel"
                            name="phoneNumber"
                            value={formData.phoneNumber}
                            onChange={handleChange}
                            placeholder="0 (500) 000 00 00"
                            required
                        />
                    </label>

                    <label form-item="mt-2">
                        <span>{pageJSON.email.find(x => x.languageCode === language)?.title}</span>
                        <input
                            type="email"
                            name="email"
                            value={formData.email}
                            onChange={handleChange}
                            required
                        />
                    </label>

                    <label form-item="mt-2">
                        <span>{pageJSON.description.find(x => x.languageCode === language)?.title}</span>
                        <textarea
                            name="description"
                            value={formData.description}
                            onChange={handleChange}
                            rows="6"
                            required
                        ></textarea>
                    </label>

                    <label form-button="mt-2">
                        <button type="submit" disabled={isWorking}>
                            {isWorking ? 'Gönderiliyor...' : pageJSON.send.find(x => x.languageCode === language)?.title}
                        </button>
                    </label>
                </form>
                {isSuccess !== null && (
                    <div modal-result="">
                        <div result={isSuccess ? "success" : "fail"}>
                            <i icon="mat-round md">{isSuccess ? "check_circle_outline" : "info_outline"}</i>
                            <span>{isSuccess ? "Mesajınız gönderilmiştir." : "Mesajınız gönderilemedi, tekrar deneyiniz."}</span>
                        </div>
                    </div>
                )}
            </div>
            <div modal-backdrop="" style={{ background: 'rgba(var(0 0 0) / 0%)' }}></div>
        </div>
    );
}

export default ContactModal;