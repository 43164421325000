// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useLanguage } from '../../contexts/LanguageContext';
import { title } from 'process';
import { Helmet } from 'react-helmet';

const Section = styled.section``;
const ContainerDiv = styled.div``;
const HeaderDiv = styled.div``;
const GridItem = styled.div``;
const Image = styled.img``;

const pageJSON = {
    headerTitle: [
        {
            title: 'Bize Ulaşın',
            languageCode: 'tr'
        },
        {
            title: 'Contact us',
            languageCode: 'en'
        }
    ],
    headerShortDescription: [
        {
            title: 'Ürün ve hizmetlerimiz hakkındaki yorum ve önerilerin bizim için değerli. Aklına takılan soruları yanıtlamak ve sana yardımcı olmak için AHL Pay her zaman yanında.',
            languageCode: 'tr'
        },
        {
            title: 'Your comments and suggestions about our products and services are valuable to us. AHL Pay is always here to answer your questions and help you.',
            languageCode: 'en'
        }
    ]
    ,
    customerservice: [
        {
            title: <><h3>Customer service</h3>
                <ul>
                    <li><span>Phone Number:</span> 444 90 66</li>
                    <li>
                        Email: destek@ahlpay.com.tr
                    </li>
                </ul></>,
            languageCode: 'en'
        }
        ,
        {
            title: <><h3>Müşteri Hizmetleri</h3>
                <ul>
                    <li><span>Telefon:</span> 444 90 66</li>
                    <li>
                        E-posta: destek@ahlpay.com.tr
                    </li>
                </ul></>,
            languageCode: 'tr'
        }
    ],
}

const ContactPage = () => {
    const { language } = useLanguage();
    const childrens = <main page="inside">
        <Helmet><title>Bize Ulaşın</title></Helmet>
        <section element="pageheader">
            <ContainerDiv>
                <HeaderDiv>
                
                    <h1><br /></h1>
                    <h1><br /></h1>
                    <h1><br /></h1>
                    <h1>{pageJSON.headerTitle.find(x => x.languageCode == language)?.title}</h1>
                    <h2>
                        {pageJSON.headerShortDescription.find(x => x.languageCode == language)?.title}
                    </h2>
                </HeaderDiv>
                <div>
                    <Image src="/assets/uploads/pageheader/bize_ulasin.png" alt="Bize Ulaşın" />
                </div>
            </ContainerDiv>
        </section>
        <section element="contact">
            <div>
                <grid grid="contact" grid-rows="2x">
                    <GridItem grid-item="">
                    {pageJSON.customerservice.find(x => x.languageCode == language)?.title}
                    </GridItem>
                </grid>
            </div>
        </section>
    </main>;
    return <MainLayout children={childrens} />
};

export default ContactPage;