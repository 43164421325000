// @ts-nocheck
import React, { useState } from 'react';
import MainLayout from '../layouts/MainLayout';
import { Helmet } from 'react-helmet';
import { useLanguage } from '../../contexts/LanguageContext';

const GeneralDirectors = [
    {
        name: {
            tr: 'Enver ÇETİN',
            en: 'Enver ÇETİN'
        },
        title: {
            tr: 'Yönetim Kurulu Üyesi & Genel Müdür',
            en: 'Board Member & General Manager'
        },
        bioLink: '/assets/legals/EnverCetin.pdf',
        cv: {
            tr: "Enver Çetin: \n\nBilkent Üniversitesi İktisat Bölümü mezunu Enver Çetin, üniversite öncesi eğitimini İstanbul, İngiltere ve Kanada’da gerçekleştirmiştir. İş hayatına dış ticaret ile başlamış, iki yılı aşkın süren dış ticaret faaliyetlerini genellikle Çin’de yerleşik olarak sürdürmüştür. Sonrasında kariyerine bankacılık ile devam etmiştir. Kuveyt Türk Katılım Bankası’nın Hazine Grubu’nda yatırım fonu yönetimi ile başladığı Hazine kariyerinde, beş yıl süresince özellikle altın, kıymetli madenler ve döviz piyasalarında görevler üstlenmiştir. Almanya’nın Frankfurt şehrinde bankacılık kariyerine devam ederek, KT Bank AG’de iki yılı aşkın süre boyunca Hazine ve FI Müdürü olarak görev almıştır. Bankacılık kariyerinde son olarak Türkiye Emlak Katılım Bankası’nın kuruluşu itibariyle Hazine Müdürü olarak yaklaşık dört yıl görev almıştır. Enver Çetin, AHL Pay markası ile faaliyetlerini sürdüren Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş.’de 18 Nisan 2022 itibariyle Genel Müdür ve Yönetim Kurulu Üyesi olarak görev yapmaktadır. DEİK’te Fintek İş Konseyi Üyesi ve Bankacılık ve Finans Çalışma Grubu Üyesi olarak da aktif görevler üstlenmektedir. Çok iyi derecede İngilizce ve temel seviyede Almanca bilen Enver Çetin, evli ve iki çocuk babasıdır.",
            en: "Enver Çetin: \n\nEnver Çetin, a graduate of the Department of Economics at Bilkent University, completed his pre-university education in Istanbul, England, and Canada. He began his career in foreign trade, spending over two years in this field, mostly based in China. He later transitioned to banking, starting in the Treasury Group of Kuveyt Türk Participation Bank, managing investment funds. Over five years, he held roles focused on gold, precious metals, and foreign exchange markets. He continued his banking career in Frankfurt, Germany, as the Treasury and FI Manager at KT Bank AG for over two years. His last banking role was as the Treasury Manager at Türkiye Emlak Participation Bank, serving for approximately four years since its establishment. Since April 18, 2022, Enver Çetin has been the General Manager and Board Member of Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş., operating under the AHL Pay brand. He also actively serves as a member of the Fintech Business Council and the Banking and Finance Working Group at DEİK. Fluent in English and with basic knowledge of German, Enver Çetin is married and has two children."
        }
    },
    {
        name: {
            tr: "Görkem BİLEKLİ",
            en: "Görkem BİLEKLİ"
        },
        title: {
            tr: "Bilgi Teknolojileri Genel Müdür Yardımcısı",
            en: "Deputy General Manager of Information Technologies"
        },
        bioLink: "/assets/legals/GorkemBilekli.pdf",
        cv: {
            tr: "Görkem Bilekli: \n\n06.06.1979 doğumlu Görkem Bilekli, Işık Üniversitesi Bilgi Teknolojileri bölümünden 2005 yılında mezun olmuştur. Web Teknolojileri, E-ticarette Sorunlar ve Elektronik Ödeme Sistemleri alanlarında aldığı eğitimlerle uzmanlaşmış, 17 yıllık bilgi teknolojileri kariyerinde geliştirme metodolojileri, ekip yönetimi ve mentorluk konularında öncü deneyimler edinen Bilekli, BT müdürlüğü görevinden 01.03.2023 tarihi itibariyle AHL Pay’in BT stratejisinin planlanması ve yürütülmesinden sorumlu CTO görevine atanmıştır.",
            en: "Görkem Bilekli: \n\nBorn on June 6, 1979, Görkem Bilekli graduated from the Department of Information Technologies at Işık University in 2005. Specializing in Web Technologies, E-commerce Challenges, and Electronic Payment Systems through his education, he gained leading experiences in development methodologies, team management, and mentorship during his 17-year IT career. As of March 1, 2023, Bilekli has been appointed as CTO, responsible for planning and implementing AHL Pay's IT strategy, transitioning from his previous role as IT Manager."
        }
    },
    {
        name: {
            tr: "Ahmet URAN",
            en: "Ahmet URAN"
        },
        title: {
            tr: "İç Kontrol Müdürü",
            en: "Internal Audit Manager"
        },
        bioLink: "/assets/legals/AhmetUran.pdf",
        cv: {
            tr: "Ahmet Uran: \n\n1989 yılında doğan Ahmet Uran, İstanbul Üniversitesi İktisat Fakültesi İktisat Bölümü’nden 2013 yılında mezun olmuştur. “Hizmet Kalitesinin Ölçümlenmesi: Katılım Bankacılığı Üzerine Bir Araştırma” konulu tezi yazarak Yıldız Teknik Üniversitesi İşletme Yönetimi Tezli Yüksek Lisans bölümünden mezun olmuştur. Hali hazırda Yıldız Teknik Üniversitesi İngilizce İktisat Tezli Yüksek Lisans bölümünde bir yüksek lisans daha yapmaktadır. Kariyerine 2015 yılında Albaraka Türk Katılım Bankası A.Ş.’de Müfettiş Yardımcısı olarak başlayan Uran, 2,5 yıl Birevim Tasarruf Finansman A.Ş.’de iç kontrol ve uyum birimini kurmak üzere İç Kontrol ve Uyum Müdürü olarak görev yapmıştır. Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş.’de Mart 2024 itibarıyla İç Kontrol Müdürü olarak görevine devam etmektedir.",
            en: "Ahmet Uran: \n\nBorn in 1989, Ahmet Uran graduated from the Faculty of Economics at Istanbul University in 2013. He completed his thesis on 'Measuring Service Quality: A Study on Participation Banking' and earned a Master's degree in Business Administration with a thesis from Yıldız Technical University. He is currently pursuing another Master's degree in English Economics with a thesis at Yıldız Technical University. Uran began his career in 2015 as an Assistant Inspector at Albaraka Türk Participation Bank. He then worked at Birevim Savings Finance Inc. for 2.5 years, establishing the internal control and compliance unit as the Internal Control and Compliance Manager. As of March 2024, he continues his role as Internal Audit Manager at Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş."
        }
    },
    {
        name: {
            tr: "Cansu ATALAY",
            en: "Cansu ATALAY"
        },
        title: {
            tr: "Uyum ve Risk Müdürü",
            en: "Compliance and Risk Manager"
        },
        bioLink: "/assets/legals/CansuAtalay.pdf",
        cv: {
            tr: "Cansu Atalay: \n\n1987 yılında doğan Cansu Atalay Anadolu Üniversitesi İşletme bölümünden 2014 yılında mezun olmuştur. Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş.’de Ocak 2023 itibarıyla Uyum ve Risk Müdürü olarak görev almaktadır.",
            en: "Cansu Atalay: \n\nBorn in 1987, Cansu Atalay graduated from the Department of Business Administration at Anadolu University in 2014. She has been serving as the Compliance and Risk Manager at Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. since January 2023."
        }
    }
];

const GeneralDirectorsPage = () => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');
    const { language } = useLanguage();

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent('');
    };

    return (
        <MainLayout>
            <Helmet>
                <title>{language === 'tr' ? 'Kurumsal Yönetim' : 'Corporate Governance'}</title>
            </Helmet>
            <section style={styles.corporateGovernance}>
                <br /><br /><br /><br /><br /><br /><br />
                <h2 style={styles.header}>{language === 'tr' ? 'Kurumsal Yönetim' : 'Corporate Governance'}</h2>
                <section className="element-faq-general-directors" element="faq">
                    <div>
                        <ul tabs="">
                            <li tab-id="tab-3"><a href="/YonetimBilgi">{language === 'tr' ? 'Genel Bilgiler' : 'General Information'}</a></li>
                            <li tab-id="tab-2"active="true"><a href="/UstYonetim">{language === 'tr' ? 'Üst Yönetim' : 'Management Team'}</a></li>
                            <li tab-id="tab-1"><a href="/YonetimKurulu">{language === 'tr' ? 'Yönetim Kurulu' : 'Board of Directors'}</a></li>
                        </ul>
                    </div>
                </section>
                <div style={styles.directorsList}>
                    {GeneralDirectors.map((director, index) => {
                        const imageUrl = director.bioLink.replace('.pdf', '.png'); 
                        return (
                            <div key={index} style={styles.directorCard}>
                                <div style={{ ...styles.cardImage, backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover' }}></div>
                                <div style={styles.cardContent}>
                                    <h3>{director.name[language]}</h3>
                                    <br />
                                    <p style={styles.directorTitle}>{director.title[language]}</p>
                                    <button
                                        onClick={() => openModal(director.cv[language])}
                                        style={styles.cardLink}
                                    >
                                        {language === 'tr' ? 'Özgeçmiş' : 'Resume'}
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {isModalOpen && (
                    <div style={styles.modalOverlay} onClick={closeModal}>
                        <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                            <button style={styles.closeButton} onClick={closeModal}>×</button>
                            <p>
                                <strong>{modalContent.split('\n')[0]}</strong><br />
                                {modalContent.split('\n').slice(1).join('\n')}
                            </p>
                        </div>
                    </div>
                )}
                <br /><br /><br /><br />
            </section>
        </MainLayout>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    corporateGovernance: {
        padding: '45px',
        textAlign: 'left',
    },
    header: {
        fontSize: '3rem',
        marginBottom: '-40px',
        color: '#6c757d',
        textAlign: 'left',
    },
    directorsList: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: '20px',
    },
    directorCard: {
        width: '250px',
        height: '350px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        textAlign: 'center',
    },
    cardImage: {
        width: '100%',
        height: '250px',
        backgroundColor: '#f0f0f0',
        borderRadius: '9px',
    },
    cardContent: {
        padding: '1px 0',
        width: '100%',
    },
    cardLink: {
        fontSize: '0.9rem',
        color: '#adb5bd',
        textDecoration: 'none',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        textAlign: 'center',
    },
    directorTitle: {
        color: '#adb5bd',
        fontSize: '1rem',
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modalContent: {
        background: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '700px',
        maxWidth: '90%',
        textAlign: 'center',
        position: 'relative',
        overflowY: 'auto',
        maxHeight: '88vh',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'transparent',
        border: 'none',
        fontSize: '1.5rem',
        cursor: 'pointer',
    },
};

export default GeneralDirectorsPage;
