// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useEffect, useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';

const Section = styled.section``;
const campaigns = [
  {
    "id": 1,
    "title": "DÜNYA KATILIM BANKASI A.Ş",
    "subTitle": "",
    "detail": "Temsilci Ticaret Unvanı: DÜNYA KATILIM BANKASI A.Ş\n\nİl / Ülke: İstanbul / Türkiye\n\nTemsilci Adresi: Yamanevler Mah. Ahmet Tevfik İleri Cad. No: 1/ 3 Ümraniye İstanbul\n\nTemsilci MERSİS Numarası: 0007001548100028\n\nTemsilci Faaliyet Alanı: Finans\n\nTemsilci İnternet Adresi: www.dunyakatilim.com.tr\n\nTemsilcinin Sunduğu Ödeme Hizmeti: DGR - Diğer, EKP - Elektronik Para Hesabından Kişiden Kişiye Para Transferi, HFO - Ödeme Hesabından Fatura Ödemeleri, EPH - Mobil Ödeme, KPH - Kişiden Kişiye Para Transferi, HPC - Ödeme Hesabından Para Çekilmesi, HPY - Ödeme Hesabına Para Yatırılması\n\nTemsilci Çalışma Modeli: Fiziki ve Online",
    "languageCode": "tr",
    "imagePath": "https://dunyakatilim.com.tr/site/images/dunya-katilim-logo.svg"
  },
  {
    "id": 1,
    "title": "DÜNYA KATILIM BANKASI A.Ş",
    "subTitle": "",
    "detail": "Representative Trade Name: DÜNYA KATILIM BANKASI A.Ş\n\nCity / Country: Istanbul / Turkey\n\nRepresentative Address: Yamanevler Mah. Ahmet Tevfik İleri Cad. No: 1/3 Ümraniye, Istanbul\n\nRepresentative MERSIS Number: 0007001548100028\n\nRepresentative Area of Activity: Finance\n\nRepresentative Website: www.dunyakatilim.com.tr\n\nPayment Services Offered by the Representative:\n\nDGR - Other\nEKP - Person-to-Person Transfer from Electronic Money Account\nHFO - Bill Payments from Payment Account\nEPH - Mobile Payment\nKPH - Person-to-Person Transfer\nHPC - Withdrawal from Payment Account\nHPY - Deposit into Payment Account\n\nRepresentative Operating Model: Physical and Online",
    "languageCode": "en",
    "imagePath": "https://dunyakatilim.com.tr/site/images/dunya-katilim-logo.svg"
  }  
];

const CampaignsPage = () => {
  const { language } = useLanguage();
  const [activeCampaign, setActiveCampaign] = useState(campaigns[0]?.id);
  const location = useLocation();

  useEffect(() => {
    if (location.search) {
      let locationId = location.search.split("?id=")[1];
      setActiveCampaign(Number(locationId));
    }
  }, [location]);

  const renderCampaignDetail = (detail) => {
    return detail.split('\n').map((line, index) => (
      <p key={index} style={{ marginBottom: '15px' }}>{line}</p>
    ));
  };

  const childrens = (
    <main page="inside">
      <Helmet>
        <title>{language === 'tr' ? 'Temsilciliklerimiz' : ' Representatives'}</title>
      </Helmet>
      <section element="campaigns">
  <h2 style={{ fontSize: '36px',  textAlign: 'center', marginBottom: '20px' }}>
    {language === 'tr' ? 'Temsilciliklerimiz' : 'Representatives'}
  </h2>
  <div>
    <aside>
      <ul>
        {_renderCampaigns(language, activeCampaign, setActiveCampaign)}
      </ul>
    </aside>
    <content>
      {campaigns.filter(x=>x.languageCode==language).map((item) => {
        return item.id === activeCampaign ? (
          <>
            <span style={{ fontSize: '34px',  marginBottom: '10px', display: 'block' }}>
              {item.title}
              <br /><br />
            </span>
            <picture>
              <img src={item.imagePath} alt={item.title} style={{ width: '390px', height: 'auto' }} />
            </picture>
            <article>
              <div>
                {renderCampaignDetail(item?.detail || '')}
              </div>
            </article>
          </>
        ) : null;
      })}
    </content>
  </div>
</section>+2
    </main>
  );
  return <MainLayout children={childrens} />;
};

const _renderCampaigns = (language, activeCampaign, setActiveCampaign) => {
  return campaigns.filter(x=>x.languageCode==language).map((item) => (
    <li key={item.id} active={activeCampaign == item.id ? 'true' : undefined}>
      <a href="javascript:void(0)" onClick={() => { setActiveCampaign(item.id); }} title="">
        {item.title}
      </a>
    </li>
  ));
};

export default CampaignsPage;
