// @ts-nocheck
import React, { useState, useEffect } from 'react';
import MainLayout from '../layouts/MainLayout';
import { Helmet } from 'react-helmet';
import { useLanguage } from '../../contexts/LanguageContext';

const Directors = () => {
    const { language } = useLanguage();
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [modalContent, setModalContent] = useState('');

    const directors = [
        {
            "name": {
                "tr": "Ahmet AHLATCI",
                "en": "Ahmet AHLATCI"
            },
            "title": {
                "tr": "Yönetim Kurulu Başkanı",
                "en": "Chairman of the Board of Directors"
            },
            "bioLink": "/assets/legals/AhmetAhlatcı.pdf",
            "cv": {
                "tr": "Ahmet Ahlatcı: \n\nAhmet Ahlatcı 1957 yılında Çorum’da doğmuştur. Lisans eğitimine 1977 yılında İzmir Bornova Üniversitesi Fen Edebiyat Fakültesi Fizik Bölümünde başlamış olup Hitit Üniversitesi Fen Edebiyat Fakültesi Fizik Bölümü’nde tamamlamıştır. İş yaşamına 1984 tarihinde Çorum’da kuyumculukla başlamış, 1992 yılında Ahlatcı Döviz ve Kıymetli Madenler Müessesini kurarak T.C. Merkez Bankası Para ve Efektif Piyasaları ve İstanbul Altın Borsası üyesi olmuştur. Yaptığı yatırımlarla Türkiye’nin kıymetli madenler alanında, özellikle altın üretim ve ihracatında en önemli bir rol üstlenmiştir. Ahmet Ahlatcı’nın Yönetim Kurulu Başkanlığını yaptığı Ahlatcı Holding ise 2024 yılı itibari ile 58 şirketi ve 5 binin üzerinde çalışanıyla bugün Enerji, Sağlık, Teknoloji, Otomotiv, Finans, Gayrimenkul, Madencilik, Kıymetli Madenler, Savunma Sanayi ve Havacılık sektörlerinde faaliyet göstermektedir.",
                "en": "Ahmet Ahlatcı: \n\nAhmet Ahlatcı was born in 1957 in Çorum. He started his undergraduate education in 1977 at the Department of Physics at Izmir Bornova University Faculty of Arts and Science and completed it at the Department of Physics at Hitit University. He started his business life in 1984 with jewelry in Çorum and in 1992 founded Ahlatcı Döviz ve Kıymetli Madenler Müessesesi, becoming a member of the Central Bank of the Republic of Turkey Money and Effective Markets and the Istanbul Gold Exchange. Through his investments, he has played a significant role in Turkey's precious metals sector, especially in gold production and export. As of 2024, Ahlatcı Holding, chaired by Ahmet Ahlatcı, operates in Energy, Healthcare, Technology, Automotive, Finance, Real Estate, Mining, Precious Metals, Defense Industry, and Aviation sectors with 58 companies and over 5,000 employees."
            }
        },
        {
            "name": {
                "tr": "Ahmet Emin AHLATCI",
                "en": "Ahmet Emin AHLATCI"
            },
            "title": {
                "tr": "Yönetim Kurulu Üyesi",
                "en": "Board Member"
            },
            "bioLink": "/assets/legals/EminAhlatcı.pdf",
            "cv": {
                "tr": "Ahmet Emin Ahlatcı: \n\nAhmet Emin Ahlatcı 1989 yılında doğmuştur. 2009 yılında Amerika Birleşik Devletleri Temple Üniversitesi İşletme Fakültesi Finans bölümünden mezun olduktan sonra, New York’da Bank of America, ve İsviçre UBS’te hazine bankacılığı ve özel bankacılık alanlarında çalışarak deneyim kazanmıştır. 2012 yılından beri Ahlatcı Holding Yönetim Kurulu Başkan Vekili olan Ahmet Emin Ahlatcı, Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş. başta olmak üzere Türkiye’nin en büyük rafinerisi Ahlatcı Metal Rafineri A.Ş., küresel barut fabrikası, elektronik atıklardan değerli madenlerin kazanımı tesislerinin, v.b. kurulmasında ve grup şirketlerinin halka arzında kilit rol oynamıştır. Enerya Enerji A.Ş. ve Ahlatcı Doğal Gaz A.Ş.’nin halka açılmasında liderlik etmiştir. Grubun kıymetli madenler özellikle altın ihracatına liderlik eden Ahmet Emin Ahlatcı, Ahlatcı Kuyumculuk ve Ahlatcı Metal Rafineri’nin Türkiye’nin ihracat lideri olmasında önemli rol üstlenmiştir. Ahmet Emin Ahlatcı, halen Ahlatcı Holding A.Ş. ve bağlı tüm iştiraklerde Yönetim Kurulu Başkan Vekili görevini sürdürmektedir.",
                "en": "Ahmet Emin Ahlatcı: \n\nAhmet Emin Ahlatcı was born in 1989. After graduating from Temple University's Business Faculty, Finance Department in the United States in 2009, he gained experience working in treasury banking and private banking at Bank of America in New York and UBS in Switzerland. Since 2012, Ahmet Emin Ahlatcı has been the Vice Chairman of the Board of Directors at Ahlatcı Holding, playing a pivotal role in the establishment and IPO of group companies, including Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş., Turkey’s largest refinery Ahlatcı Metal Refinery A.Ş., a global gunpowder factory, and facilities recovering precious metals from electronic waste. He led the IPOs of Enerya Enerji A.Ş. and Ahlatcı Doğal Gaz A.Ş. Ahmet Emin Ahlatcı has also been instrumental in making Ahlatcı Jewelry and Ahlatcı Metal Refinery leaders in Turkey's gold export market. He continues to serve as Vice Chairman of the Board of Directors at Ahlatcı Holding A.Ş. and all its subsidiaries."
            }
        },
        {
            "name": {
                "tr": "Ates AHLATCI",
                "en": "Ates AHLATCI"
            },
            "title": {
                "tr": "Yönetim Kurulu Üyesi",
                "en": "Board Member"
            },
            "bioLink": "/assets/legals/AtesAhlatcı.pdf",
            "cv": {
                "tr": "Ates Ahlatcı: \n\n1997 yılında Çankaya Amerikan Hastanesi’nde dünyaya gelen Ateş Ahlatcı Çorum’da ilk öğretim ve lise hayatını tamamladıktan sonra Bahceşehir Üniversitesini kazanmıştır. Çalışma hayatına ise Ahlatcı Grubu’nda yer alan kuyumculuk ve döviz şirketlerinde görev alarak başlayan Ateş Ahlatcı, daha sonra yönetim kurulu üyesi olduğu aracı kurum olan Ahlatcı Yatırım Menkul Değerler’de devam etmektedir. 2019 yazında İtalya’nın Milano şehrinde bulunan Bocconi Üniversitesi Dijital Pazarlama eğitimi almıştır. 2014 yılında Ahlatcı Holding A.Ş bünyesinde yer alan kuyumculuk ve döviz sektöründe göreve başlayan Ateş Ahlatcı, kanuni uyum sürecinin oluşturulmasında etkin ve verimli olarak görev üstlenmiştir. Ateş Ahlatcı, Ahlatcı Yatırım ve Menkul Değerler A.Ş’de 2017 yılında yönetim kurulu üyesi olarak başladığı görevine 2018 yılından bu yana Yönetim Kurulu Üyesi ve Pazarlama, Operasyon Müdürü olarak devam etmektedir.",
                "en": "Ates Ahlatcı: \n\nBorn in 1997 at Çankaya American Hospital, Ates Ahlatcı completed his primary and secondary education in Çorum and later attended Bahçeşehir University. He began his career working at jewelry and exchange companies within the Ahlatcı Group and later continued at the brokerage firm Ahlatcı Yatırım Menkul Değerler, where he serves as a board member. In the summer of 2019, he attended Bocconi University in Milan, Italy, for digital marketing education. Ates Ahlatcı started his career in 2014 within the jewelry and exchange sectors under Ahlatcı Holding A.Ş. and played an active and efficient role in establishing legal compliance processes. Since 2018, Ates Ahlatcı has been serving as a Board Member and Marketing and Operations Manager at Ahlatcı Yatırım Menkul Değerler A.Ş., where he initially began as a board member in 2017."
            }
        },
        {
            "name": {
                "tr": "Enver ÇETİN",
                "en": "Enver ÇETİN"
            },
            "title": {
                "tr": "Yönetim Kurulu Üyesi & Genel Müdür",
                "en": "Board Member & General Manager"
            },
            "bioLink": "/assets/legals/EnverCetin.pdf",
            "cv": {
                "tr": "Enver Çetin: \n\nBilkent Üniversitesi İktisat Bölümü mezunu Enver Çetin, üniversite öncesi eğitimini İstanbul, İngiltere ve Kanada’da gerçekleştirmiştir. İş hayatına dış ticaret ile başlamış, iki yılı aşkın süren dış ticaret faaliyetlerini genellikle Çin’de yerleşik olarak sürdürmüştür. Sonrasında kariyerine bankacılık ile devam etmiştir. Kuveyt Türk Katılım Bankası’nın Hazine Grubu’nda yatırım fonu yönetimi ile başladığı Hazine kariyerinde, beş yıl süresince özellikle altın, kıymetli madenler ve döviz piyasalarında görevler üstlenmiştir. Almanya’nın Frankfurt şehrinde bankacılık kariyerine devam ederek, KT Bank AG’de iki yılı aşkın süre boyunca Hazine ve FI Müdürü olarak görev almıştır. Bankacılık kariyerinde son olarak Türkiye Emlak Katılım Bankası’nın kuruluşu itibariyle Hazine Müdürü olarak yaklaşık dört yıl görev almıştır. Enver Çetin, AHL Pay markası ile faaliyetlerini sürdüren Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş.’de 18 Nisan 2022 itibariyle Genel Müdür ve Yönetim Kurulu Üyesi olarak görev yapmaktadır. DEİK’te Fintek İş Konseyi Üyesi ve Bankacılık ve Finans Çalışma Grubu Üyesi olarak da aktif görevler üstlenmektedir. Çok iyi derecede İngilizce ve temel seviyede Almanca bilen Enver Çetin, evli ve iki çocuk babasıdır.",
                "en": "Enver Çetin: \n\nEnver Çetin, a graduate of the Department of Economics at Bilkent University, completed his pre-university education in Istanbul, England, and Canada. He started his career in foreign trade, mainly operating in China for over two years. He then continued his career in banking, beginning in the Treasury Group at Kuveyt Türk Participation Bank, managing investment funds. Over five years, he undertook roles in the gold, precious metals, and foreign exchange markets. He advanced his banking career in Frankfurt, Germany, working for over two years as Treasury and FI Manager at KT Bank AG. Finally, he served as Treasury Manager at Türkiye Emlak Katılım Bankası from its inception for approximately four years. As of April 18, 2022, Enver Çetin has been serving as General Manager and Board Member of Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş., operating under the AHL Pay brand. He is an active member of DEİK’s Fintech Business Council and Banking and Finance Working Group. Proficient in English and with basic German skills, Enver Çetin is married and has two children."
            }
        }
    ];    

    const openModal = (content) => {
        setModalContent(content);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setModalContent('');
    };

    return (
        <MainLayout>
            <Helmet>
                <title>{language === 'tr' ? 'Kurumsal Yönetim' : 'Corporate Governance'}</title>
            </Helmet>
            <section style={styles.corporateGovernance}>
                <br />
                <br />
                <br />
                <br />
                <br /><br /><br />
                <br />
                <h2 style={styles.header}>{language === 'tr' ? 'Kurumsal Yönetim' : 'Corporate Governance'}</h2>
                <section className="element-faq-general-directors" element="faq">
                    <div>
                        <ul tabs="">
                            <li tab-id="tab-3"><a href="/YonetimBilgi">{language === 'tr' ? 'Genel Bilgiler' : 'General Information'}</a></li>
                            <li tab-id="tab-2"><a href="/UstYonetim">{language === 'tr' ? 'Üst Yönetim' : 'Management Team'}</a></li>
                            <li tab-id="tab-1" active="true"><a href="/YonetimKurulu">{language === 'tr' ? 'Yönetim Kurulu' : 'Board of Directors'}</a></li>
                        </ul>
                    </div>
                </section>
                <div style={styles.directorsList}>
                    {directors.map((director, index) => {
                        const imageUrl = director.bioLink.replace('.pdf', '.png'); 
                        return (
                            <div key={index} style={styles.directorCard}>
                                <div style={{ ...styles.cardImage, backgroundImage: `url(${imageUrl})`, backgroundSize: 'cover' }}></div>
                                <div style={styles.cardContent}>
                                    <h3>{director.name[language]}</h3>
                                    <br />
                                    <p style={styles.directorTitle}>{director.title[language]}</p>
                                    <button
                                        onClick={() => openModal(director.cv[language])}
                                        style={styles.cardLink}
                                    >
                                        {language === 'tr' ? 'Özgeçmiş' : 'Resume'}
                                    </button>
                                </div>
                            </div>
                        );
                    })}
                </div>
                {isModalOpen && (
                    <div style={styles.modalOverlay} onClick={closeModal}>
                        <div style={styles.modalContent} onClick={(e) => e.stopPropagation()}>
                            <button style={styles.closeButton} onClick={closeModal}>×</button>
                            <p>
                                <strong>{modalContent.split('\n')[0]}</strong><br />
                                {modalContent.split('\n').slice(1).join('\n')}
                            </p>
                        </div>
                    </div>
                )}
                <br /><br /><br /><br />
            </section>
        </MainLayout>
    );
};

const styles: { [key: string]: React.CSSProperties } = {
    corporateGovernance: {
        padding: '45px',
        textAlign: 'left',
    },
    header: {
        fontSize: '3rem',
        marginBottom: '-40px',
        color: '#6c757d',
        textAlign: 'left',
    },
    directorsList: {
        display: 'flex',
        justifyContent: 'flex-start',
        flexWrap: 'wrap',
        gap: '20px',
    },
    directorCard: {
        width: '250px',
        height: '350px',
        border: '1px solid #ccc',
        borderRadius: '8px',
        padding: '10px',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'flex-start',
        textAlign: 'center',
    },
    cardImage: {
        width: '100%',
        height: '250px',
        backgroundColor: '#f0f0f0',
        borderRadius: '9px',
    },
    cardContent: {
        padding: '1px 0',
        width: '100%',
    },
    cardLink: {
        fontSize: '0.9rem',
        color: '#adb5bd',
        textDecoration: 'none',
        cursor: 'pointer',
        backgroundColor: 'transparent',
        border: 'none',
        textAlign: 'center',
    },
    directorTitle: {
        color: '#adb5bd',
        fontSize: '1rem',
    },
    modalOverlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.5)',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        zIndex: 1000,
    },
    modalContent: {
        background: '#fff',
        padding: '20px',
        borderRadius: '8px',
        width: '700px',
        maxWidth: '90%',
        textAlign: 'center',
        position: 'relative',
        overflowY: 'auto',
        maxHeight: '88vh',
    },
    closeButton: {
        position: 'absolute',
        top: '10px',
        right: '10px',
        background: 'transparent',
        border: 'none',
        fontSize: '1.5rem',
        cursor: 'pointer',
    },
};

export default Directors;
