// @ts-nocheck
import React, { useState, useEffect } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import AppHeader from '../../components/AppHeader';
import Footer from '../../components/Footer';
import Helmet from 'react-helmet';

import MainLayout from '../layouts/MainLayout';

const pageJSON = {
    headerTitle: [
        {
            title: 'Başvuru formu',
            languageCode: 'tr'
        },
        {
            title: 'Application form',
            languageCode: 'en'
        }
    ],
    shortDescription: [
        {
            title: <h5>Başvuru için <a href="mailto:ik@ahlpay.com.tr">ik@ahlpay.com.tr</a> adresinden özgeçmişinizi iletebilirsiniz.</h5>,
            languageCode: 'tr'
        },
        {
            title: <h5>You can submit your resume to <a href="mailto:ik@ahlpay.com.tr">ik@ahlpay.com.tr</a> for the application.</h5>,
            languageCode: 'en'
        }
    ],
    firstAndLastName: [
        {
            title: 'Adınız soyadınız',
            languageCode: 'tr'
        },
        {
            title: 'Full Name',
            languageCode: 'en'
        }
    ],
    phoneNumber: [
        {
            title: 'Telefon Numaranız',
            languageCode: 'tr'
        },
        {
            title: 'Phone Number',
            languageCode: 'en'
        }
    ],
    email: [
        {
            title: 'E-posta adresiniz',
            languageCode: 'tr'
        },
        {
            title: 'Email',
            languageCode: 'en'
        }
    ],
    note: [
        {
            title: 'Başvuru Notu',
            languageCode: 'tr'
        },
        {
            title: 'Application Note',
            languageCode: 'en'
        }
    ],
    dropCV: [
        {
            title: "CV'inizi sürükleyebilir  veya seçebilirsiniz.",
            languageCode: 'tr'
        },
        {
            title: 'Drop your CV here',
            languageCode: 'en'
        }
    ],
    send: [
        {
            title: "Gönder",
            languageCode: 'tr'
        },
        {
            title: 'Send',
            languageCode: 'en'
        }
    ],
    selectedFile: [
        {
            title: "Seçilen dosya",
            languageCode: 'tr'
        },
        {
            title: 'Selected file',
            languageCode: 'en'
        }
    ],
    success: [
        {
            title: "CV'niz başvurunuzla birlikte bizlere ulaştı. Başvurunuzun uygunluk kriterlerine uygun olduğu durumda sizinle iletişime geçeceğiz.",
            languageCode: 'tr'
        },
        {
            title: 'Your resume has been received along with your application. If your application meets the eligibility criteria, we will get in touch with you.',
            languageCode: 'en'
        }
    ],
    Urun: [
        {
            title: "Başvurulan Ürün",
            languageCode: 'tr'
        },
        {
            title: 'Applied Product',
            languageCode: 'en'
        }
    ],
    il: [
        {
            title: "İl/İlçe",
            languageCode: 'tr'
        },
        {
            title: 'City/districts',
            languageCode: 'en'
        }
    ],
    isletme: [
        {
            title: "İşletme Türü",
            languageCode: 'tr'
        },
        {
            title: 'Business Type',
            languageCode: 'en'
        }
    ],
    isletmeweb: [
        {
            title: "İşletme web sitesi",
            languageCode: 'tr'
        },
        {
            title: 'Business website',
            languageCode: 'en'
        }
    ],
    isletmeweb: [
        {
            title: "İşletme web sitesi",
            languageCode: 'tr'
        },
        {
            title: 'Business website',
            languageCode: 'en'
        }
    ],
    isletmeTitle: [
        {
            title: "İşletme ünvanı",
            languageCode: 'tr'
        },
        {
            title: 'Company Name',
            languageCode: 'en'
        }
    ],
    İsletmeCiro: [
        {
            title: "Aylık tahmini ciro",
            languageCode: 'tr'
        },
        {
            title: 'Estimated monthly turnover',
            languageCode: 'en'
        }
    ],
    Kvkk: [
        {
            title: "AHL Pay'in Kişisel verilerin İşlenmesi metnini okudum ve kabul ediyorum",
            languageCode: 'tr'
        },
        {
            title: 'I have read and accept AHL Pays Personal Data Processing Policy.',
            languageCode: 'en'
        }
    ],
    İletisim: [
        {
            title: "AHL Pay'in kampanyalarını tanıtım, hatırlatma, bilgilendirme amaçlı ve/veya hizmet iyileştirmesi amacıyla SMS, e-posta ya da telefon aracılığıyla benimle iletişime geçmesine izin veriyorum",
            languageCode: 'tr'
        },
        {
            title: 'I consent to AHL Pay contacting me via SMS, email, or phone for the purposes of promoting campaigns, reminders, notifications, and/or service improvements.',
            languageCode: 'en'
        }
    ],
    fıyat1: [
        {
            title: "500.000 TL 'nin altı",
            languageCode: 'tr'
        },
        {
            title: 'Below 500,000 TL',
            languageCode: 'en'
        }
    ],
    fıyat2: [
        {
            title: "5.000.000 TL 'nin üstü",
            languageCode: 'tr'
        },
        {
            title: 'Above 5,000,000 TL',
            languageCode: 'en'
        }
    ],
    Apply1: [
        {
            title: "Başvuru",
            languageCode: 'tr'
        },
        {
            title: 'Apply',
            languageCode: 'en'
        }
    ],
    Urun2: [
        {
            title: "Kurumsal Kart",
            languageCode: 'tr'
        },
        {
            title: 'Corporate Card',
            languageCode: 'en'
        }
    ],
    Urun1: [
        {
            title: "Kurumsal Hesap",
            languageCode: 'tr'
        },
        {
            title: 'Corporate Account',
            languageCode: 'en'
        }
    ],
    Urun3: [
        {
            title: "Yazar Kasa Pos",
            languageCode: 'tr'
        },
        {
            title: 'Cash Register',
            languageCode: 'en'
        }
    ],
    sec: [
        {
            title: "Seçiniz...",
            languageCode: 'tr'
        },
        {
            title: 'Please select...',
            languageCode: 'en'
        }
    ],
};

const ApplyPage = ({ openModal, setOpenModal, selectedProductId }) => {
    const { language } = useLanguage();
    const [isWorking, setIsWorking] = useState(false);
    const [isSuccess, setIsSuccess] = useState(null);
    const [formData, setFormData] = useState({
        productType: '0',
        fromEmail: '',
        fromPhoneNumber: '',
        fromFullName: '',
        fromCountyDistrict: '',
        businessType: '',
        businessTitle: '',
        businessWebAddress: '',
        businessEstimatedTurnoverPerMonth: '0',

    });
    useEffect(() => {
        if (selectedProductId) {
            setFormData((prevData) => ({
                ...prevData,
                productType: selectedProductId
            }));
        }
    }, [selectedProductId]);

    const handleChange = (e) => {
        setFormData({ ...formData, [e.target.name]: e.target.value });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setIsWorking(true);

        const requestData = {
            ...formData,
            ip: "78.135.100.149"
        };

        try {
            const baseUrl = process.env.REACT_APP_API_ADDRESS;
            const response = await fetch(baseUrl + '/Communication/SendBusinessEmail', {
                method: 'POST',
                headers: {
                    'Accept': 'application/json',
                    'Content-Type': 'application/json',
                    'X-Requested-With': 'XMLHttpRequest',
                },
                body: JSON.stringify(requestData),
            });

            setIsWorking(false);
            if (response.ok) {
                setIsSuccess(true);
            } else {
                setIsSuccess(false);
            }
        } catch (error) {
            console.error('Error submitting form:', error);
            setIsSuccess(false);
            setIsWorking(false);
        }
    };


    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        const checkDevice = () => {
            setIsMobile(window.innerWidth <= 768); // Mobile screen size threshold (adjust as needed)
        };

        checkDevice(); // Run on mount
        window.addEventListener('resize', checkDevice); // Update on window resize

        return () => {
            window.removeEventListener('resize', checkDevice); // Cleanup
        };
    }, []);

    return (
        <MainLayout>
            <main style={{
                width: isMobile ? '90%' : '50%',
                placeSelf: 'center',
                margin: 'auto'
            }} page="inside">
                <br />
                {/* <span modal-close="" onClick={() => setOpenModal(false)}><i icon="mat-symbol xl">close</i></span> */}
                <br />
                <h4>{pageJSON.headerTitle.find(item => item.languageCode === language)?.title}</h4>
                {isSuccess !== null && (
                    <div modal-result="">
                        <div result={isSuccess ? "success" : "fail"}>
                            <i icon="mat-round md">{isSuccess ? "check_circle_outline" : "info_outline"}</i>
                            <span>{isSuccess ? "Mesajınız gönderilmiştir." : "Mesajınız gönderilemedi, tekrar deneyiniz."}</span>
                        </div>
                    </div>
                )}
                <form
                    form="product" method="post" onSubmit={handleSubmit}>
                    <label form-item="mt-2">
                        <span>{pageJSON.Urun.find(item => item.languageCode === language)?.title}</span>
                        <select
                            name="productType"
                            value={formData.productType}
                            onChange={handleChange}
                        >
                            <option value="0">{pageJSON.sec.find(item => item.languageCode === language)?.title}</option>
                            <option value="AltinPOS">AltınPOS</option>
                            <option value="AndroidPOS">AndroidPOS</option>
                            <option value="CepPOS">CepPOS</option>
                            <option value="SanalPOS">SanalPOS</option>
                            <option value="KurumsalHesap">{pageJSON.Urun1.find(item => item.languageCode === language)?.title}</option>
                            <option value="KurumsalKart">{pageJSON.Urun2.find(item => item.languageCode === language)?.title}</option>
                            <option value="OKCPOS">{pageJSON.Urun3.find(item => item.languageCode === language)?.title}</option>
                        </select>
                    </label>
                    <label form-item="mt-2">
                        <span>{pageJSON.firstAndLastName.find(item => item.languageCode === language)?.title}</span>
                        <input type="text" name="fromFullName" value={formData.fromFullName} onChange={handleChange} />
                    </label>

                    <label form-item="mt-2">
                        <span>{pageJSON.phoneNumber.find(item => item.languageCode === language)?.title}</span>
                        <input type="tel" name="fromPhoneNumber" value={formData.fromPhoneNumber} onChange={handleChange} placeholder="0 (500) 000 00 00" />
                    </label>

                    <label form-item="mt-2">
                        <span>{pageJSON.email.find(item => item.languageCode === language)?.title}</span>
                        <input type="email" name="fromEmail" value={formData.fromEmail} onChange={handleChange} />
                    </label>

                    <label form-item="mt-2">
                        <span>{pageJSON.il.find(item => item.languageCode === language)?.title}</span>
                        <input type="text" name="fromCountyDistrict" value={formData.fromCountyDistrict} onChange={handleChange} />
                    </label>

                    <label form-item="mt-2">
                        <span>{pageJSON.İletisim.find(item => item.languageCode === language)?.title}</span>
                        <input type="text" name="businessType" value={formData.businessType} onChange={handleChange} />
                    </label>
                    <label form-item="mt-2">
                        <span>{pageJSON.isletmeweb.find(item => item.languageCode === language)?.title}</span>
                        <input type="text" name="businessWebAddress" value={formData.businessWebAddress} onChange={handleChange} />
                    </label>

                    <label form-item="mt-2">
                        <span>{pageJSON.isletmeTitle.find(item => item.languageCode === language)?.title}</span>
                        <input type="text" name="businessTitle" value={formData.businessTitle} onChange={handleChange} />
                    </label>
                    <label form-item="mt-2">
                        <span>{pageJSON.İsletmeCiro.find(item => item.languageCode === language)?.title}</span>
                        <select name="businessEstimatedTurnoverPerMonth" value={formData.businessEstimatedTurnoverPerMonth} onChange={handleChange}>
                            <option value="0">{pageJSON.İsletmeCiro.find(item => item.languageCode === language)?.title}</option>
                            <option value="500.000 TL 'nin altı">{pageJSON.fıyat1.find(item => item.languageCode === language)?.title}</option>
                            <option value="500.000 - 1.000.000 TL">500.000 - 1.000.000 TL</option>
                            <option value="1.000.000 - 5.000.000 TL">1.000.000 - 5.000.000 TL</option>
                            <option value="5.000.000 TL 'nin üzeri">{pageJSON.fıyat2.find(item => item.languageCode === language)?.title}</option>
                        </select>
                    </label>
                    <label form-item="checkbox mt-2">
                        <input type="checkbox" name="privacyPolicy" />
                        <var>{pageJSON.Kvkk.find(item => item.languageCode === language)?.title}</var>
                    </label>
                    <label form-item="checkbox mt-2">
                        <input type="checkbox" name="marketingConsent" />
                        <var>{pageJSON.İletisim.find(item => item.languageCode === language)?.title}</var>
                    </label>

                    <label form-item="recaptcha mt-3">
                        <span className="g-recaptcha" data-sitekey="6LfD3PIbAAAAAJs_eEHvoOl75_83eXSqpPSRFJ_u"></span>
                    </label>

                    <label form-button="mt-2">
                        <button type="submit" disabled={isWorking}>
                            {isWorking ? 'Gönderiliyor...' : pageJSON.send.find(item => item.languageCode === language)?.title}
                        </button>
                    </label>
                </form>
                <br />
            </main>
        </MainLayout>
    );
};

export default ApplyPage;
