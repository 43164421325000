// @ts-nocheck
import MainLayout from '../layouts/MainLayout';
import { useLanguage } from '../../contexts/LanguageContext';
import { Helmet } from 'react-helmet';

const pageJSON = {
    detail: [
        {
            data: <>
                <h1>
                    AHL Pay:<br />
                    Finans ve Teknolojinin Kesişme Noktası.
                </h1>
                {/* <picture><img src="assets/uploads/about/1024x768.jpg" alt="" /></picture> */}
                <article>
                    <blockquote>AHL Pay markası ile faaliyetlerini sürdüren Ahlatcı Ödeme ve Elektronik Para Hizmetleri AŞ, bir Ahlatcı Holding'in iştirakidir.</blockquote><br />

                    Ahlatcı Holding'in altın, finans, enerji, ar-ge, otomotiv, gayrimenkul, sağlık ve savunma sanayi sektörlerindeki başarılarına katılan AHL Pay, 2019 yılında lisans alarak finans sektöründe faaliyetlere başlamıştır. 2021’de alınan faaliyet izinleri sonrası, 2022 yılının haziran ayı itibarıyla da sürdürülebilir çalışmalarına devam etmektedir.<br /><br />

                    Ahlatcı Ödeme ve Elektronik Para Hizmetleri AŞ, 6493 sayılı kanun çerçevesinde TCMB tarafından yetkilendirilen Elektronik Para ve Ödeme Hizmetleri kuruluşudur. Bankalararası Kart Merkezi (BKM)’nin banka dışı üyesidir. TROY, Mastercard ve Visa şemaları ile kart ihraç etme yetkisine sahip olan elektronik para kuruluşudur.<br /><br />

                    AHL Pay, ödeme hizmetleri ve elektronik para alanında inovatif çözümler sunmakta ve ödeme alanındaki faaliyetlerin gelişimini sağlamaktadır. Müşterilerine en güvenli ve en hızlı hizmeti sunma amacıyla çalışmalarını sürdüren AHL Pay, müşteri memnuniyetine önem vermektedir.<br /><br />

                    AHL Pay’li olan bireysel kullanıcılar; para transferleri, kurum ve fatura ödemeleri, kartlı ve kartsız işlemler, fiziki ve kaydi kıymetli maden işlemleri, altın alma ve satma, yatırım işlemleri, dijital oyun satın alma ve dünya markalarının fırsatlarından yararlanmaktadır. AHL Pay mobil ile tüm finansal akış hızlıca takip edilmekte ve güvenle alışveriş yapılmaktadır.<br /><br />

                    Aynı zamanda kurumsal müşterilere özel avantajlı komisyon oranları sunulmaktadır. SanalPOS, CepPOS, AndroidPOS ve AltınPOS gibi kurumsal hizmetlerle beraber yenilikçi ödeme çözümleri de sağlamaktadır.<br /><br />

                    Kuyumculuk sektörüne özel geliştirdiği AltınPOS ile Türkiye genelinde yüzlerce kuyumcuya POS işlemleri için geniş kapsamlı bir ürün hizmeti sunmaktadır. Kuyumcuların sadece ödeme işlemi sonrasında kur riskini ve ürün tedariğini de içeren AltınPOS, Türkiye’deki kuyumcular için önemli bir çözüm kaynağı olmuştur.<br /><br />

                    SanalPOS ürünü ile e-ticaret sektörlerindeki birçok ticari ve kurumsal firmaya hizmet vermektedir.<br /><br />

                    Mobil uygulama olan CepPOS, tüm telefon ve tabletlerin POS cihazına dönüşmesini sağlamaktadır. Uygulama ile hızlı ve güvenli bir şekilde ödeme tahsis edilmektedir.<br /><br />

                    BenimFaturam.com.tr web sitesi ve mobil uygulaması ile ülke genelindeki tüm kurum faturalarının taksitli ödenebilmesini ve nakit iade alınabilmesine imkân tanımaktadır.<br /><br />

                    AHL Pay geniş kaynakları ve güçlü altyapısıyla, finans sektöründeki değişimlere uyum sağlayarak, dijital çağın getirdiği fırsatları en iyi şekilde değerlendiren bir finans kurumu olarak öne çıkmaktadır.<br /><br />

                    İnternet alışverişlerinde kullanılması için Sanal Card ile tüm alışverişlerinizde geçerli fiziki kart müşterilerimize sunulmaktadır.<br /><br />

                    Sanal Card, AHL Pay hesabına bağlı bir şekilde oluşturulan sanal kart ile online alışverişler hızlı ve güvenli bir şekilde gerçekleştirilmektedir.<br /><br />

                </article>

            </>,
            languageCode: 'tr'
        },
        {
            data: <>
                <h1>
                    AHL Pay:<br />
                    Finans ve Teknolojinin Kesişme Noktası.
                </h1>

                {/* <picture><img src="assets/uploads/about/1024x768.jpg" alt="" /></picture> */}

                <article>

<blockquote>AHL Pay, operating under the name Ahlatcı Payment and Electronic Money Services Inc., is a subsidiary of Ahlatcı Holding.</blockquote><br />

Joining Ahlatcı Holding’s proven success in gold, finance, energy, R&D, automotive, real estate, healthcare, and defense industries, AHL Pay obtained its license in 2019 and officially began its operations in the financial sector. With the operational permits acquired in 2021, AHL Pay has continued its sustainable growth since June 2022.<br /><br />

Ahlatcı Payment and Electronic Money Services Inc. is an Electronic Money and Payment Services Institution authorized by the Central Bank of the Republic of Turkey (CBRT) under Law No. 6493. It is a non-bank member of the Interbank Card Center (BKM) and authorized to issue cards within the TROY, Mastercard, and Visa.<br /><br />

AHL Pay delivers innovative solutions in payment services and electronic money, driving the development of payment technologies. Committed to providing the most secure and fastest service, AHL Pay prioritizes customer satisfaction.<br /><br />

Services for Retail Accounts:<br /><br />

AHL Pay offers its retail users a comprehensive suite of financial services, including money transfers, utility and bill payments, card and cardless transactions, physical and digital precious metal operations, gold buying and selling, investment transactions, digital game purchases, and access to exclusive deals from global brands. Users may easily monitor their financial transactions and shop securely online with the AHL Pay mobile app.<br /><br />

Corporate Accounts:<br /><br />

AHL Pay offers corporate clients competitive commission rates and personalized solutions, including Virtual POS, CepPOS, Android POS, and AltınPOS.<br /><br />

The AltınPOS, specifically designed for the jewelry sector, offers a robust solution for hundreds of jewelers across Turkey. More than payment, AltınPOS solves the problem of currency risk and product supply chain problems, becoming an important innovation for the industry.<br /><br />

The Virtual POS solution supports the widest range of e-commerce businesses with its secure and fast transaction processing facility.<br /><br />

By using the mobile application CepPOS, any smartphone or tablet may be turned into a POS device to assure fast and safe payment collections.<br /><br />

BenimFaturam.com.tr allows making bill payments with installment options and earning cashback all over the country through its web platform and mobile application.<br /><br />

Sustainable Innovation in Finance:<br /><br />

Equipped with all the vast resources and a strong infrastructure, AHL Pay has adapted to the ever-changing world of finance, embracing all the benefits the digital era can offer.<br /><br />

For online shopping, AHL Pay provides customers with a Virtual Card, seamlessly integrated with their AHL Pay account. This secure and efficient tool allows users to perform online transactions safely and with ease.<br /><br />

AHL Pay distinguishes itself as a forward-thinking financial institution, integrating cutting-edge technologies to reshape the payment ecosystem.<br /><br />

</article>

            </>,
            languageCode: 'en'
        }
    ]
}

const AboutUsPage = () => {

    const { language } = useLanguage();

    const childrens = <main page="inside">
        <Helmet>
            <title>Hakkımızda</title>
        </Helmet>
        <section element="about">
            <div>
                {pageJSON.detail.find(x => x.languageCode == language)?.data}
            </div>
        </section>
    </main>;

    return <MainLayout children={childrens} />
};

export default AboutUsPage;
