// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext'; // Dil desteği için bağlam

const ProductPage = ({ productData, h5Text }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    useEffect(() => {
        document.title = pageTitle; // Tarayıcı başlığını güncelle
    }, [pageTitle]);

    return (
        <>
            <NavBar />
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1>
                        <br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div key={index} grid-item="">
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>{description}</blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt="Para İste Posteri"
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                    <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target="_blank"
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target="_blank"
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage(); // Kullanıcının dil tercihini belirle
    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };
    const productData = {
        tr: {
            pageTitle: 'Para İste',
            headerTitle: 'Para İste',
            title: "AHL Pay ile Para İste",
            shortDescription:
                'İhtiyaç anında hızlı ve kolayca para talep et, talebin onaylanır onaylanmaz paran hesabına geçsin. AHL Pay, Para İste özelliği ile, ihtiyaç duyduğunuz anda size hızlı ve pratik bir çözüm sunar.',
            description:
                "Transferler ve ardından Para iste ekranına tıkla, Para İste yöntemini seç (Hesap Numarası, Telefon Numarası veya QR ile) ve isteyeceğin para tutarını yaz, talebi oluştur. Karşı taraf talebini onayladıktan sonra, tutar hesabına anında geçsin.",
            imagePath: 'assets/uploads/products/phones/paragonder_phone.svg',
            title2: <>Nasıl<span> Para İsterim?</span></>,
            childrens: [
                {
                    title: 'Hesap Numarası ile para iste',
                    desc: 'Talep oluşturacağın hesap numarasını yaz, karşı tarafa hemen yansısın.'
                },
                {
                    title: 'Telefon Numarası ile para iste',
                    desc: 'Para isteme işlemini, kullanıcının telefon numarasını girerek veya rehberinden seçerek kolayca gerçekleştir.'
                },
                {
                    title: 'Talebin onaylanır onaylanmaz tutar hesabında',
                    desc: 'Karşı taraf talebini onaylar onaylamaz tutar anında hesabına geçsin.'
                }
            ]
        },
        en: {
            pageTitle: 'Request Money',
            headerTitle: 'Request Money',
            title: "Request Money with AHL Pay",
            shortDescription:
                'Easily and quickly request money when you need it, and once your request is approved, the money is instantly transferred to your account. AHL Pay’s Request Money feature provides a quick and practical solution when you need it.',
            description:
                "Click Transfers and then Request Money, choose the Request Money method (Wallet Number, Phone Number, or QR), and enter the amount you want to request. Create the request, and once the recipient approves, the amount is instantly transferred to your account.",
            imagePath: 'assets/uploads/products/phones/paragonder_phone.svg',
            title2: <>How to<span> Request Money?</span></>,
            childrens: [
                {
                    title: 'Request money with Account Number',
                    desc: 'Enter the account number to create a request, and it will be instantly reflected to the recipient.'
                },
                {
                    title: 'Request money with Phone Number',
                    desc: 'Easily complete the money request process by entering the user’s phone number or selecting it from your contacts.'
                },
                {
                    title: 'Instant Transfer upon Approval',
                    desc: 'Once the recipient approves the request, the amount is instantly credited to your account.'
                }
            ]
        }
    };

    // Kullanıcının diline uygun veriyi seç
    const localizedProductData = productData[language] || productData.tr;

    return (
        <div>
            <AppHeader />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* ProductPage bileşeni çağrılıyor */}
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
