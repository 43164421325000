// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useState } from 'react';
import { useLanguage } from '../../contexts/LanguageContext';
import { Helmet } from 'react-helmet';

const Section = styled.section``;
const ContainerDiv = styled.div``;
const HeaderDiv = styled.div``;
const GridItem = styled.div``;
const Image = styled.img``;
const Button = styled.button`
  background-color: #002abe;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:hover {
    background-color: #001b8c;
  }
`;
const pageJSON = {
    title1: [
        {
            title: 'Sözleşmeler',
            languageCode: 'tr'
        },
        {
            title: 'Agreements',
            languageCode: 'en'
        }
    ]
}

const legalInfos = [
    {
        id: 1,
        title: 'Üye İş Yeri Sözleşmesi',  
        link: 'assets/legals/UyeIsYeriSozlesmesi.pdf',
        languageCode: 'tr'
    },
    {
        id: 2,
        title: 'KULLANICI ÇERÇEVE SÖZLEŞMESİ',  
        link: 'assets/legals/KullanıcıCerceve.pdf',
        languageCode: 'tr'
    },
    {
        id: 3,
        title: 'KURUMSAL CÜZDAN KULLANICI ÇERÇEVE SÖZLEŞMESİ',  
        link: 'assets/legals/KurumsalCuzdan.pdf',
        languageCode: 'tr'
    },
]
const Agreements = () => {
    const { language } = useLanguage(); 
    const [activeAgr, setActiveAgr] = useState(2);

    const childrens = (
        <main page="inside">
            <Helmet>
                <title>{language === 'tr' ? 'Yasal Bilgiler' : 'Legal Information'}</title>
            </Helmet>
            <section element="legalinfos">
                <h2>{language === 'tr' ? 'Yasal Bilgiler' : 'Legal Information'}</h2>

                <div>
                    <aside>
                        <ul>
                            {legalInfos.map((item) => {
                                if (item.link) {
                                    return (
                                        <li
                                            key={item.id}
                                            className={activeAgr === item.id ? 'active' : undefined}
                                        >
                                            <a
                                                href={item.link}
                                                target={'_blank'}
                                                title={item.title}
                                            >
                                                {item.title}
                                            </a>
                                        </li>
                                    );
                                } else {
                                    return (
                                        <li
                                            key={item.id}
                                            className={activeAgr === item.id ? 'active' : undefined}
                                        >
                                            <a
                                                href="javascript:void(0)"
                                                onClick={() => {
                                                    setActiveAgr(item.id);
                                                }}
                                                title={item.title}
                                            >
                                                {item.title}
                                            </a>
                                        </li>
                                    );
                                }
                            })}
                        </ul>
                    </aside>
                    <content>
                        {legalInfos.find((x) => x.id === activeAgr)?.data}
                    </content>
                </div>
            </section>
        </main>
    );

    return <MainLayout children={childrens} />;
};

export default Agreements;
