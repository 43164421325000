// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import KurumsalNavBar from '../../../components/KurumsalNavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext'; // Dil desteği için bağlam

const ProductPage = ({ productData, h5Text }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    useEffect(() => {
        document.title = pageTitle; // Tarayıcı başlığını güncelle
    }, [pageTitle]);

    return (
        <>
            <KurumsalNavBar />
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1>
                        <br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div key={index} grid-item="">
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>{description}</blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt="AltınPOS Posteri"
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                    <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target="_blank"
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target="_blank"
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage(); // Kullanıcının dil tercihini belirle
    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };
    const productData = {
        tr: {
            pageTitle: 'AHL Pay Altın POS',
            headerTitle: 'Altın POS',
            title: "AHL Pay Altın POS",
            shortDescription:
                'Tüm yabancı kartlarla ödeme al, birçok bankanın kredi kartı ile taksitli işlem gerçekleştir. AltınPos ile ödeme işlemini gerçekleştiren üye işyeri kuyumcu, oluşan altın bakiyesi ile, Altın-TL kuru üzerinden her türlü fiziksel altın ürünü temin edebilir.',
            description:
                'Kuyumculara Özel POS ile, AndroidPOS üzerinden TL karşılığı Altın, Dolar, Euro alım-satım işlemlerini gerçekleştir.',
            imagePath: 'assets/uploads/products/products/kurumsal_altinpos.png',
            title2: <><span>AHL Pay </span>AltınPOS</>,
            childrens: [
                {
                    title: 'Altın-TL Kuru İle Altın Alımı',
                    desc: 'POS işlemin tamamlandıktan sonra, hesabındaki Türk Lirası karşılığında, "haftanın 6 günü" avantajlı Altın-TL kuru ile anında altın al.'
                },
                {
                    title: 'Tüm Kuyum Ürünlerinin Temini',
                    desc: 'Altın-TL işlemin sonucunda oluşan bakiyen ile herhangi bir kuyum ürününü avantajlı "milyem" oranlarıyla teslim al.'
                },
                {
                    title: 'Altın Transfer İşlemleri',
                    desc: "Takasbank Altın Transfer Sistemi'ne üye bankalardaki altın hesaplarına altın transferi gerçekleştirebilirsin."
                }
            ]
        },
        en: {
            pageTitle: 'AHL Pay Altın POS',
            headerTitle: 'Altın POS',
            title: "AHL Pay Altın POS",
            shortDescription:
                'Accept payments with all foreign cards, and process installments with many banks’ credit cards. Using AltınPOS, the jeweler merchant can supply all kinds of physical gold products based on their gold balance through the Gold-TL rate.',
            description:
                'With the POS system tailored for jewelers, conduct Gold, Dollar, and Euro transactions through AndroidPOS in exchange for TL.',
            imagePath: 'assets/uploads/products/products/kurumsal_altinpos.png',
            title2: <><span>AHL Pay </span>AltınPOS</>,
            childrens: [
                {
                    title: 'Gold Purchase with Gold-TL Rate',
                    desc: 'After completing the POS transaction, instantly purchase gold with your TL balance based on the advantageous Gold-TL rate, available "6 days a week".'
                },
                {
                    title: 'Supply All Jewelry Products',
                    desc: 'Use your balance resulting from the Gold-TL transaction to obtain any jewelry product at advantageous "millesimal" rates.'
                },
                {
                    title: 'Gold Transfer Transactions',
                    desc: 'Transfer gold to gold accounts at banks participating in the Takasbank Gold Transfer System.'
                }
            ]
        }
    };

    // Kullanıcının diline uygun veriyi seç
    const localizedProductData = productData[language] || productData.tr;

    return (
        <div>
            <AppHeader />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* ProductPage bileşeni çağrılıyor */}
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
