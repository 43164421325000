// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import NavBar from '../../../components/NavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext'; // Dil desteği için bağlam

const ProductPage = ({ productData, h5Text }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    useEffect(() => {
        document.title = pageTitle; // Tarayıcı başlığını güncelle
    }, [pageTitle]);

    return (
        <>
            <NavBar />
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1>
                        <br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div key={index} grid-item="">
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>{description}</blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt="Para Gönder Posteri"
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                    <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target="_blank"
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target="_blank"
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage(); // Kullanıcının dil tercihini belirle
    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };
    const productData = {
        tr: {
            pageTitle: 'Para Gönder',
            headerTitle: 'Para Gönder',
            title: "AHL Pay ile Para Gönder",
            shortDescription:
                'AHL PAY hesabından diğer banka hesaplarına hızlı ve güvenle para transferi yapabilirsin. Ayrıca, AHL Pay hesabından tüm AHL Pay üyelerine telefon/hesap numaraları veya dilersen QR kod ile para gönderebilirsin.',
            description:
                "Banka hesabına para göndermek için uygulama üzerinden Transferler ve ardından Para Gönder'e tıkla. Para göndermek istediğin yöntemi seç ve göndermek istediğin tutarı belirle, tutar anında hesabına geçsin. Ayrıca AHL Pay kullanıcılarına, QR Kod, Hesap Numarası, Telefon numarası ve Kart Numarası ile para gönderebilirsin.",
            imagePath: 'assets/uploads/products/phones/odemeiste_phone.svg',
            title2: <>Nasıl<span> Para Gönderirim?</span></>,
            childrens: [
                {
                    title: 'Anında Hesabında',
                    desc: 'Yapacağın para transferi anında hesabında'
                },
                {
                    title: '7/24 Para Transferi',
                    desc: 'Hafta içi veya hafta sonu, para transferini dilediğin zaman yap'
                },
                {
                    title: 'Hızlı ve Güvenilir',
                    desc: 'Hızlı ve güvenli bir şekilde para transferi gerçekleştir'
                }
            ]
        },
        en: {
            pageTitle: 'Send Money',
            headerTitle: 'Send Money',
            title: "Send Money with AHL Pay",
            shortDescription:
                'You can quickly and securely transfer money from your AHL Pay account to other bank accounts. You can also send money to all AHL Pay members via phone/wallet numbers or QR codes.',
            description:
                "To send money to a bank account, click Transfers and then Send Money in the app. Select the method you want to use and specify the amount you want to send; the amount will be transferred instantly. You can also send money to AHL Pay users via QR Code, Wallet Number, Phone Number, and Card Number.",
            imagePath: 'assets/uploads/products/phones/odemeiste_phone.svg',
            title2: <>How to<span> Send Money?</span></>,
            childrens: [
                {
                    title: 'Instant Transfer',
                    desc: 'Your money transfer is instantly credited'
                },
                {
                    title: '24/7 Money Transfer',
                    desc: 'Make money transfers anytime, weekdays or weekends'
                },
                {
                    title: 'Fast and Secure',
                    desc: 'Transfer money quickly and securely'
                }
            ]
        }
    };

    // Kullanıcının diline uygun veriyi seç
    const localizedProductData = productData[language] || productData.tr;

    return (
        <div>
            <AppHeader />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* ProductPage bileşeni çağrılıyor */}
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
