// @ts-nocheck

import MainLayout from '../layouts/MainLayout';
import React, { useState, useEffect, useRef } from 'react';
import { motion } from 'framer-motion';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import 'swiper/css/effect-fade';
import { Autoplay, Navigation, Pagination, EffectFade } from 'swiper/modules';
import { useLanguage } from '../../contexts/LanguageContext';
import ProductInformationModal from '../products/modals/ProductInformationModal';
import { CardStack } from './components/CardStack';
import { HomeProducts } from './components/HomeProducts';
import { Helmet } from 'react-helmet';

const sliders = [
    {
        title: 'AHL Pay',
        subtitle: 'Yeni Finansal Özgürlük',
        sliderType: 'default',
        button: '',
        imagePath: '/assets/uploads/slider/ekran.svg',
        languageCode: 'tr'
    },
    {
        title: 'New Financial',
        subtitle: 'Freedom AHL Pay',
        sliderType: 'default',
        button: '',
        imagePath: '/assets/uploads/slider/ekran.svg',
        languageCode: 'en'
    }
];

const parallaxProducts = [
    {
        title: 'Fırsatları Kaçırma!',
        subTitle: 'Uygulamayı indir, sana özel kampanyaları kaçırma!',
        imagePath: '/assets/uploads/home-spots/kampanya.svg',
        backgroundColor: '#EEF6FF',
        languageCode: 'tr'
    },
    {
        title: "Don't Missing Out Opportunities",
        subTitle: "Download App, Don't missing out opportunities",
        imagePath: '/assets/uploads/home-spots/kampanya.svg',
        backgroundColor: '#EEF6FF',
        languageCode: 'en'
    },
    {
        title: 'Alışverişte Güvenli ve Hızlı Ödeme!',
        subTitle: 'Sanal kartlarımızla güvenli ödemelerin tadını çıkarın, alışverişinizi kolaylaştırın. Finansal özgürlüğünü bir adım öteye taşıyoruz.',
        imagePath: '/assets/uploads/home-spots/spot2.svg',
        backgroundColor: '#F2FBF5',
        languageCode: 'tr',
        path: "/products-individual?p=AHLCard"
    },
    {
        title: 'Virtual Card For You!',
        subTitle: 'You do payment instantly with virtual card, Earn as spend',
        imagePath: '/assets/uploads/home-spots/spot2.svg',
        backgroundColor: '#F2FBF5',
        languageCode: 'en',
        path: "/products-individual?p=AHLCard"
    },
    {
        title: 'Güvenli ve Kesintisiz İşlem!',
        subTitle: 'FAST sistemi ile AHL Pay üzerinden tüm işlemlerini 7/24 güvenli bir şekilde yapabilirsin.',
        imagePath: '/assets/uploads/home-spots/fasthome.svg',
        backgroundColor: '#F5F2FB',
        languageCode: 'tr',
        path: "/products-individual?p=GuvenceBedeli"
    },
    {
        title: 'Secure and Straight Through Processing',
        subTitle: 'With the FAST system, you can make all your processing securely 24/7 via AHL Pay.',
        imagePath: '/assets/uploads/home-spots/spot3.png',
        backgroundColor: '#F5F2FB',
        languageCode: 'en',
        path: "/products-individual?p=GuvenceBedeli"
    }
];

const campaigns = {
    headerTitle: [
        {
            title: "Sana özel kampanyaların keyfini çıkar!",
            languageCode: 'tr'
        },
        {
            title: 'Active Campaigns!',
            languageCode: 'en'
        }
    ],
    goldTitle: [
        {
            title: <h3>Altın ve Gümüş ile Geleceğini Şekillendir</h3>,
            languageCode: 'tr'
        },
        {
            title: <h3>Gold and Silver Prices</h3>
            ,
            languageCode: 'en'
        }
    ],
    goldTitle2: [
        {
            title: <h4>Yatırım yapabileceğiniz Altın ve Gümüş fiyatları</h4>,
            languageCode: 'tr'
        },
        {
            title: <h4>Gold and Silver prices that you can invest</h4>
            ,
            languageCode: 'en'
        }
    ],
    CardTitle: [
        {
            title: <h2 className='title1'>Senin AHL Card'ın hangisi?<br></br>Tercihini yap, fırsatlardan yararlan</h2>,
            languageCode: 'tr'
        },
        {
            title: <h2 className='title1'>The New Face of Modern Finance</h2>
            ,
            languageCode: 'en'
        }
    ],
    CardDescription: [
        {
            title: <h3 className='description1'> Günümüzün hızlı finans dünyasında, fiziki kartımız şık tasarımı ve işlevselliğiyle öne çıkıyor.</h3>,
            languageCode: 'tr'
        },
        {
            title: <h3 className='description1'>In today's fast-paced financial world, our physical card stands out with its sleek design and functionality.</h3>
            ,
            languageCode: 'en'
        }
    ],
    More: [
        {
            title: <p>Daha Fazla</p>,
            languageCode: 'tr'
        },
        {
            title: <p>More</p>
            ,
            languageCode: 'en'
        }
    ],
    cardpoint1: [
        {
            title: <ul className="description-list">
                <li>Kartını aktive et, 200 TL nakit hediye kazan</li>
                <li>Her şey kontrolün altında</li>
                <li>Dilediğin gibi harca, bütçeni aşma</li>
                <li>Harcadıkça&nbsp;<strong>Cashback</strong>&nbsp;kazan</li>

            </ul>,
            languageCode: 'tr'
        },
        {
            title: <ul className="description-list">
                <li>Activate your card, earn 200 TL cash bonus</li>
                <li>Everything under control</li>
                <li>Spend as you wish, stay within your budget</li>
                <li>Earn&nbsp;<strong>Cashback</strong>&nbsp;as you spend</li>
            </ul>,

            languageCode: 'en'
        }
    ],
    data: [
        {
            "id": 12,
            "title": "Soruyu Bil, İmzalı forma ve şort kazan",
            "subTitle": "Soruyu doğru cevapla, Çeşitli futbolculardan imzalı forma ve şort kazanan şanslı kişilerden biri sen ol!",
            "detail": "İmzalı Galatasaray Forma ve Şort Talihlileri:\n\nASİL \n1- MECİT ENGİN KANTAR\n2- AHMET BERK AYLAK\n3- DUHAN AK\n4- EMİRHAN VARİÇLİ\n5- MUSTAFA SARAY\n6- ABDURRAHMAN EZRİK\n7- BÜNYAMİN COŞKUN\n8- HARUN BULUT\n9- EMRE KARAGÖZ\n10- BARAN ARDA DORUKÖZ\n\nYEDEK \n1- ŞÜKRİYE SEVİNÇ\n2- AKİF KONCA\n3- VOLKAN ÇELİKOĞLU\n4- EDA CELİK\n5- ESMA ÖZSOBACI\n6- ARDA OCAK\n7- ALİ CAN YALÇIN\n8- İLKER HACIOĞLU\n9- EMRE CAKIR\n10- ABDULLAH ÖZDAL\n11- METİN TİRYAKİ\n12- GIYASETTİN YASİN KAVAK\n13- İREM MIDIK\n14- SEZER AYDIN\n15- TALHA ÇOPUROĞLU",
            "languageCode": "tr",
            "imagePath": "/assets/uploads/products/icons/gsforma.jpeg"
        },

        {
            "id": 36,
            "title": "JACK&JONES'ta geçerli %20 İndirimi",
            "subTitle": "AHL Pay kullanıcılarına özel %20 indirim fırsatı Jack&Jones mağazalarında!",
            "detail": "AHL Pay kullanıcıları alışverişlerinde kazanmaya devam ediyor. Jack&Jones ile tarzın seni konuşsun... Hem de AHL Pay kullanıcılarına özel %20 indirim avantajı ile! AHL Pay'e özel bu kodlar sayesinde indirimli ürünlerde ekstra %20 indirimden yararlanabileceksiniz.\n\nKampanya Koşulları:\n- Kodlar, yalnızca Jack&Jones mağazalarında geçerlidir.\n- Farklı kampanyalar ile birleştirilemez.\n- Kodların son kullanım tarihleri 31.12.2024'tür.\n- AHL Pay ve Jack&Jones, kampanyada değişiklik yapma hakkını saklı tutar.",
            "languageCode": "tr",
            "imagePath": "/assets/uploads/campaigns/detail/jj.png"
        }
        ,
        {
            "id": 1,
            "title": "Herkese Galatasaray Atkısı",
            "subTitle": "Tüm AHL Pay'li taraftarlara Galatasaray Atkısı!",
            "detail": "Galatasaray – AHL Pay Atkı Kazanma Koşulları: Galatasaray - AHL Pay atkı ödülüne hak kazanabilmek için katılımcının AHL Pay'de onaylı bir hesabının olması, hesabına en az 1,00 ₺ yüklemesi ve en az 1 işlem gerçekleştirmesi gerekmektedir. Başlangıç hesap sahibi katılımcıların ödülden yararlanabilmeleri için onaylı hesap türüne geçmeleri zorunludur. Katılım, AHL Pay uygulaması üzerinden 'GALATASARAY – AHL Pay Atkı' kampanyasına katıl butonu ile alınan kodun, maç günlerinde RAMS Park'ta bulunan AHL Pay Fan Zone karavanında beyan edilmesi ile gerçekleşir ve atkı temin edilir. Kullanıcı bilgilerinin doğruluğu katılımcının sorumluluğundadır; yanlış bilgi verilmesi durumunda ödül teslim edilmez. Önceki kampanyalarda aynı ödülü kazananlar yeniden katılamaz. AHL Pay, kampanya süresini veya koşullarını önceden haber vermeksizin değiştirme veya iptal etme hakkına sahiptir. Katılımcılar, teknik aksaklıklardan kaynaklanan hak kayıplarından sorumlu tutulamaz ve tüm katılımcılar bu koşulları peşinen kabul eder.",
            "languageCode": "tr",
            "imagePath": "/assets/uploads/products/icons/gsatkı.jpeg"
        },
        {
            "id": 26,
            "title": "AHL Pay kullanıcılarına özel obilet araç kiralamalarında 250 TL indirim.",
            "subTitle": "AHL Pay kullanıcılarına özel obilet araç kiralamalarında 250 TL indirim. Kampanya, yalnızca AHL Pay kullanıcılarına özeldir.",
            "detail": "Kampanya, yalnızca AHL Pay kullanıcılarına özeldir.\nİndirim kodları, 1000 TL ve üzeri araç kiralamalarında geçerli olup, yalnızca obilet mobil uygulamasında kullanılabilir.\nKodlar, 10.12.2024 – 31.01.2025 tarihleri arasında aktif olacak ve bu süre sonunda geçerliliğini yitirecektir.\n\nİndirim kodları devredilemez, bölünemez ve başka kampanyalarla birleştirilemez. Kodlar, paraya çevrilemez ve satılamaz.\nObilet Bilişim Sistemleri A.Ş. ve AHL Pay, kampanya koşulları, kapsamı ve süresinde önceden bildirimde bulunmaksızın değişiklik yapma veya kampanyayı sonlandırma hakkını saklı tutar.\nKampanya şartlarına aykırı kullanımlarda, Obilet Bilişim Sistemleri A.Ş. ve AHL Pay hukuki haklarını saklı tutar.",
            "languageCode": "tr",
            "imagePath": "/assets/uploads/campaigns/detail/obilet.png"
        }
        ,
        {
            "id": 10,
            "title": "BRILLANTSTORE'dan %30 İndirim + Ücretsiz Kargo",
            "subTitle": "Tüm ürünlerde geçerli %30 indirim ve ücretsiz kargo avantajı!",
            "detail": "Tüm ürünlerde geçerli %30 indirim ve ücretsiz kargo fırsatını kaçırmayın! Brillantstore.com.tr’deki tüm ürünler için geçerlidir ve herhangi bir alt ya da üst sepet sınırı bulunmamaktadır.\n\nKampanya Detayları:\n- Kampanya, 31.01.2025 tarihine kadar geçerlidir.\n- Kodların son kullanım tarihi 31.03.2025’tir.\n\nİndirim Kodu Kullanımı:\n1. Beğendiğiniz ürünü sepetinize ekleyin.\n2. Sepet ekranında yer alan 'kupon kodu' alanına indirim kodunuzu yazın.\n3. 'Kullan' butonuna tıklayın ve indiriminizi hemen kazanın.\n\nNot:\n- AHL Pay ve Brillantstore, kampanya şartlarını değiştirme veya kampanyayı sonlandırma hakkını saklı tutar.",
            "languageCode": "tr",
            "imagePath": "/assets/uploads/campaigns/detail/brillant.jpeg"
        }

    ]
}

const ScrollingPage = () => {
    const [activeIndex, setActiveIndex] = useState(0);
    useEffect(() => {
        const handleScroll = () => {
            const sections = document.querySelectorAll('.section');
            let newActiveIndex = 0;
            sections.forEach((section, index) => {
                const rect = section.getBoundingClientRect();
                if (rect.top < window.innerHeight / 2 && rect.bottom > window.innerHeight / 2) {
                    newActiveIndex = index;
                }
            });
            setActiveIndex(newActiveIndex);
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <div className="container">
            <div className='left-side'>

                {dataV2.map((item, index) => {
                    return <div>
                        <div className='left-section'>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.1 }}>
                                <div>
                                    <h2 className='product-title-scroll'> {item.contents.find((x) => x.positionType === "topLeft")}</h2>
                                </div>
                                <p className='product-subtitle-scroll'> {item.contents.find((x) => x.positionType === "bottomLeft")}</p>
                            </motion.div>
                        </div>
                        <div className='left-section'>
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                transition={{ duration: 0.1 }}>
                                <div>
                                    <h2 className='product-title-scroll'> {item.contents.find((x) => x.positionType === "topRight")}</h2>
                                </div>
                                <p className='product-subtitle-scroll'> {item.contents.find((x) => x.positionType === "botomRight")}</p>
                            </motion.div>
                        </div>
                    </div>
                })}

            </div>
            <div className="scroll-page-center">
                <div className="image-container">
                    <img
                        src='/assets/images/iphone_14_max_main.png'
                        alt="iPhone"
                        className="iphone-image"
                    />
                    <div className="screen-content">
                        <motion.img
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.3 }}
                            src={data[activeIndex].imagePath}
                            alt={data[activeIndex].title}
                            className="screen-image"
                        />
                    </div>
                </div>
            </div>
            <div className="right-side">
                {data.map((item, index) => (
                    <div key={index} className="section">
                        <motion.div
                            initial={{ opacity: 0 }}
                            animate={{ opacity: 1 }}
                            transition={{ duration: 0.1 }}
                        ><div><h2 className='product-title-scroll'>{item.title}</h2></div>
                            <p className='product-subtitle-scroll'>{item.description}</p>
                        </motion.div>
                    </div>
                ))}
            </div>
        </div>
    );
};

const FinTechOffering = () => {
    const [currentOrder, setCurrentOrder] = useState([0, 1, 2]);
    const [isAnimating, setIsAnimating] = useState(false);
    const cardsRef = useRef([]);

    const dropCard = () => {
        if (isAnimating) return;

        setIsAnimating(true);
        const droppingCardIndex = currentOrder[0];
        const droppingCard = cardsRef.current[droppingCardIndex];

        if (droppingCard) {
            droppingCard.classList.add('dropping');

            setTimeout(() => {
                droppingCard.classList.remove('dropping');
                setCurrentOrder((prevOrder) => {
                    const newOrder = [...prevOrder];
                    newOrder.push(newOrder.shift());
                    return newOrder;
                });
                setIsAnimating(false);
            }, 350);
        }
    };

    const updateCardPositions = () => {
        currentOrder.forEach((cardIndex, position) => {
            const card = cardsRef.current[cardIndex];
            if (card) {
                card.style.zIndex = `${3 - position}`;
                card.style.transform = `translateY(-${position * 80}px)`;
                card.classList.toggle('back', position === 2);
            }
        });
    };

    useEffect(() => {
        if (!isAnimating) {
            updateCardPositions();
        }
    }, [currentOrder, isAnimating]);

    useEffect(() => {
        const interval = setInterval(() => {
            if (!isAnimating) {
                dropCard();
            }
        }, 3000);
        return () => clearInterval(interval);
    }, [isAnimating]);

    return (
        <div className="card-stack">
            {[0, 1, 2].map((index) => (
                <div
                    key={index}
                    className="card"
                    ref={(el) => (cardsRef.current[index] = el)}
                >
                    <img src={`/assets/images/AHL${['FTL', 'FT', 'S'][index]}.png`} alt={`Card ${index + 1}`} />
                </div>
            ))}
        </div>
    );
};

export default function Home() {

    const { language } = useLanguage();
    const [selectedProductType, setSelectedProductType] = useState(1);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [currentProduct, setCurrentProduct] = useState(null);
    const [goldPrices, setGoldPrices] = useState([]);

    const [isMobile, setIsMobile] = useState(false);

    const handleResize = () => {
        setIsMobile(window.innerWidth <= 768);
    };

    useEffect(() => {
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const handleNavClick = (event) => {
        const scrollToElement = document.querySelector(`[element][data-goto="golds"]`);
        if (scrollToElement) {
            window.scrollTo({
                top: scrollToElement.getBoundingClientRect().top + window.scrollY - 180,
                behavior: 'smooth',
            });
        }
    };

    function useMouseTracking(selector) {
        useEffect(() => {
            const element = document.querySelector(selector);
            if (!element) return;

            const handleMouseMove = (e) => {
                console.log('handle mouse move');
                const x = e.pageX - element.offsetLeft - element.offsetParent.offsetLeft;
                const y = e.pageY - element.offsetTop - element.offsetParent.offsetTop;
                element.style.setProperty('--x', `${x}px`);
                element.style.setProperty('--y', `${y}px`);
            };

            element.addEventListener('mousemove', handleMouseMove);

            return () => {
                element.removeEventListener('mousemove', handleMouseMove);
            };
        }, [selector]);
    }

    useMouseTracking('.mask1');
    useMouseTracking('.mask2');

    useEffect(() => {
        const fetchGoldPrices = async () => {
            try {
                const baseUrl = process.env.REACT_APP_API_ADDRESS;

                const response = await fetch(baseUrl + '/api/GetGoldPrices', {
                    method: 'GET',
                    headers: {
                        'Accept-Language': language === 'en' ? 'en-US' : 'tr-TR',
                    },
                });

                if (response.ok) {
                    const data = await response.json();
                    setGoldPrices(data);
                } else { 
                    console.error('request failed with status:', response.status);
                }
            } catch (error) {
                console.error('Error fetching gold prices:', error);
            }
        };

        fetchGoldPrices();
    }, [language]);

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const openDetail = (id) => {
        let current = individualProducts.find((x) => x.id === id);
        setCurrentProduct(current);
        setIsModalOpen(true);
    };

    const childrens = (
        <main page="home">
            <Helmet><title>AHL Pay</title></Helmet>
            {isModalOpen && (
                <ProductInformationModal handleCloseModal={handleCloseModal} item={currentProduct} />
            )}
            <div scrollpage="">
                <section element="homesliders">
                    <div slider="home">
                        <div slider-content="">
                            <Swiper
                                loop={true}
                                effect="fade"
                                autoplay={{ delay: 10000, disableOnInteraction: false }}
                                navigation={{
                                    nextEl: "[slider=home] [swiper-arrow=next]",
                                    prevEl: "[slider=home] [swiper-arrow=prev]",
                                }}
                                modules={[Autoplay, Navigation, EffectFade]}
                                className="swiper"
                            >
                                {sliders.filter(x => x.languageCode == language).map((item, index) => {
                                    if (item.sliderType == 'default') {
                                        return <SwiperSlide slide-type="default">
                                            <div>
                                                <h1 data-text={item.title}>
                                                    <span>{item.title}</span>
                                                </h1>
                                                <h2 data-text={item.subtitle}>
                                                    <span>{item.subtitle}</span>
                                                </h2>
                                                <picture>
                                                    <img src="/assets/uploads/slider/ekran.svg" alt={item.title + ' ' + item.subtitle} />
                                                </picture>
                                            </div>
                                        </SwiperSlide>
                                    } else {
                                        return <SwiperSlide slide-type="ltr">
                                            <div>
                                                <aside>
                                                    <h1>{item.title}
                                                    </h1>
                                                    <h1>
                                                        {item.subtitle}
                                                    </h1>
                                                    <a href="#" title="">{item.button}</a>
                                                </aside>
                                                <picture>
                                                    <img src="/assets/uploads/slider/02.png" alt="Arkadaşlarını davet et, kazan!" />
                                                </picture>
                                            </div>
                                        </SwiperSlide>
                                    }
                                })}
                            </Swiper>
                            <div swiper-arrows="">
                                <div swiper-arrow="prev"><i icon="mat-symbol">chevron_left</i></div>
                                <div swiper-arrow="next"><i icon="mat-symbol">chevron_right</i></div>
                            </div>
                        </div>
                    </div>
                </section>
                {parallaxProducts.filter((x) => x.languageCode === language).map((item, index) => (
                    <section key={index} element="homespot" style={{ backgroundColor: item.backgroundColor }}>
                        <div>
                            <div>
                                <h2>{item.title}</h2>
                                <h3>{item.subTitle}</h3>
                            </div>
                            <div><img src={item.imagePath} alt="" /></div>
                        </div>
                    </section>
                ))}
            </div>
            <section element="homespot">
                <div className='homecards'>
                    <div className="credit-card-left">
                        {campaigns.CardTitle.find((x) => x.languageCode === language)?.title}
                        <div className="green-line"></div>
                        {campaigns.cardpoint1.find((x) => x.languageCode === language)?.title}
                    </div>
                    <CardStack />
                </div>
            </section>
            <HomeProducts />
            <section element="homecampaigns">
                <div>
                    <ul>
                        <li>
                            <h2>{campaigns.headerTitle.find((x) => x.languageCode === language)?.title}</h2>
                        </li>
                        <li><div slider-pagination=""></div></li>
                    </ul>
                    <div slider="campaigns">
                        <Swiper
                            slidesPerView={1}
                            spaceBetween={60}
                            autoplay={{
                                delay: 25000,
                                disableOnInteraction: false,
                            }}
                            pagination={{
                                el: "[slider-pagination]",
                                dynamicBullets: true,
                                clickable: true,
                            }}
                            breakpoints={{
                                640: {
                                    slidesPerView: 2,
                                    spaceBetween: 40,
                                },
                                1024: {
                                    slidesPerView: 3,
                                    spaceBetween: 60,
                                },
                            }}
                            modules={[Autoplay, Pagination]}
                            className="swiper"
                             >
                            {campaigns.data.map((item) => (
                                <SwiperSlide key={item.id}>
                                    <picture>
                                        <a href={'/campaigns?id=' + item.id} title="">
                                            <img src={item.imagePath} alt="" />
                                        </a>
                                    </picture>
                                    <a href={'/campaigns?id=' + item.id} title=""><h3>{item.title}</h3></a>
                                    <a href={'/campaigns?id=' + item.id} title=""><h4>{item.subTitle}</h4></a>
                                </SwiperSlide>
                            ))}
                        </Swiper>
                    </div>
                </div>
            </section>
            <section element="homegoldprices" data-goto="golds">
                <div>
                    {campaigns.goldTitle.find((x) => x.languageCode === language)?.title}
                    {campaigns.goldTitle2.find((x) => x.languageCode === language)?.title}
                    <grid grid="homegoldprices">
                        {goldPrices.map((gold, index) => (
                            <div grid-item="" key={index}>
                                <picture><img src={gold.imagePath} alt={gold.title} /></picture>
                                <div>
                                    <span aria-label="name">{gold.title} {gold.subTitle}</span>
                                    <span aria-label="price">{gold.price.substring(0, gold.price.indexOf(language == 'tr' ? ',' : '.'))} ₺</span>
                                </div>
                            </div>
                        ))}
                    </grid>
                </div>
            </section>
        </main>
    );
    // return <main page="home"><HomeProducts /></main>;
    return (
        <MainLayout>
            {childrens}
        </MainLayout>
    );

}


