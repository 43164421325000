// @ts-nocheck
import AppHeader from '../../../components/AppHeader';
import Footer from '../../../components/Footer';
import KurumsalNavBar from '../../../components/KurumsalNavBar';
import { useEffect } from 'react';
import { useLanguage } from '../../../contexts/LanguageContext'; // Dil desteği için bağlam

const ProductPage = ({ productData, h5Text }) => {
    const {
        pageTitle,
        headerTitle,
        title,
        imagePath,
        shortDescription,
        description,
        title2,
        childrens
    } = productData;

    useEffect(() => {
        document.title = pageTitle; // Tarayıcı başlığını güncelle
    }, [pageTitle]);

    return (
        <>
            <KurumsalNavBar />
            <section element="individual-header">
                <div>
                    <div>
                        <h1>{title}</h1>
                        <br />
                        <span>{shortDescription}</span>
                        <div>
                            <grid grid="individual-products">
                                {childrens.map((child, index) => (
                                    <div key={index} grid-item="">
                                        <div>
                                            <h3>{child.title}</h3>
                                            <blockquote>{child.desc}</blockquote>
                                        </div>
                                    </div>
                                ))}
                            </grid>
                        </div>
                    </div>
                    <div>
                        <img src={`/${imagePath}`} alt={headerTitle} />
                    </div>
                </div>
            </section>
            <section element="individual-poster">
                <div>
                    <article>
                        <h2>{title2}</h2>
                        <blockquote>{description}</blockquote>
                    </article>
                    <picture>
                        <img
                            src="/assets/uploads/products/posters/diledigin_gibi.png"
                            alt="Yazar Kasa POS Posteri"
                        />
                    </picture>
                </div>
            </section>
            <div element="individual-banner">
                <div>
                    <aside>
                        {/* Dil desteğine bağlı olarak h5 metni */}
                        <h5>{h5Text}</h5>
                        <div aria-label="apps">
                            <ul>
                                <li>
                                    <a
                                        href="https://apps.apple.com/tr/app/ahl-pay/id6443716011"
                                        target="_blank"
                                        title="AHL Pay: IOS Uygulaması"
                                    >
                                        <img src="/assets/images/app-ios-lg.png" alt="AHL Pay: IOS Uygulaması" />
                                    </a>
                                </li>
                                <li>
                                    <a
                                        href="https://play.google.com/store/apps/details?id=com.ahlatci.ahlpay&hl=tr"
                                        target="_blank"
                                        title="AHL Pay: Android Uygulaması"
                                    >
                                        <img src="/assets/images/app-android-lg.png" alt="AHL Pay: Android Uygulaması" />
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </aside>
                    <picture>
                        <img src="/assets/uploads/products/banners/banner.png" alt="Finansal özgürlüğünü şimdi keşfet" />
                    </picture>
                </div>
            </div>
        </>
    );
};

const App = () => {
    const { language } = useLanguage(); // Kullanıcının dil tercihine göre metni ayarla

    const h5Texts = {
        tr: 'Finansal özgürlüğünü şimdi keşfet',
        en: 'Discover your financial freedom now'
    };

    const productData = {
        tr: {
            pageTitle: 'AHL Pay Yazar Kasa POS',
            headerTitle: 'Yazar Kasa POS',
            title: "AHL Pay Yazar Kasa POS",
            
            shortDescription:
                'Kullanıcı dostu arayüzü ile Yazar Kasa POS, işletmelerin satış süreçlerini kolaylaştırır, hızlı ve güvenli ödeme seçenekleri sunar.',
            description:
                'AHL Pay Yazar Kasa POS, işletmenin ihtiyaçlarına özel çözümler sunar, finansal işlemlerini güvenli ve hızlı bir şekilde gerçekleştirmeni sağlar.',
            imagePath: 'assets/uploads/products/products/kurumsal_yazarkasapos.png',
            title2: <><span>AHL Pay </span>Yazar Kasa POS</>,
            childrens: [
                {
                    title: 'Tüm Satışlar Tek Noktada',
                    desc: 'POS işlemin tamamlandıktan sonra, hesabındaki Türk Lirası karşılığında, işlemlerini kolayca yönet.'
                },
                {
                    title: 'Anında Satış ve Memnuniyet',
                    desc: 'Hızlı işlemler ile müşteri memnuniyetini artır.'
                },
                {
                    title: 'Kolayca Başvuru Hızlı Kurulum',
                    desc: 'Başvurunu yap, seninle hemen iletişime geçelim.'
                }
            ]
        },
        en: {
            pageTitle: 'AHL Pay Cash Register POS',
            headerTitle: 'Cash Register POS',
            title: "AHL Pay Cash Register POS",
            shortDescription:
                'With its user-friendly interface, Cash Register POS simplifies business sales processes, providing fast and secure payment options.',
            description:
                'AHL Pay Cash Register POS offers solutions tailored to your business needs, enabling you to carry out financial transactions securely and efficiently.',
            imagePath: 'assets/uploads/products/products/kurumsal_yazarkasapos.png',
            title2: <><span>AHL Pay </span>Cash Register POS</>,
            childrens: [
                {
                    title: 'All Sales in One Place',
                    desc: 'After completing your POS transaction, manage your processes easily with Turkish Lira balances.'
                },
                {
                    title: 'Instant Sales and Satisfaction',
                    desc: 'Enhance customer satisfaction with fast transactions.'
                },
                {
                    title: 'Easy Application and Quick Setup',
                    desc: 'Submit your application and we will contact you immediately.'
                }
            ]
        }
    };

    // Kullanıcının diline uygun veriyi seç
    const localizedProductData = productData[language] || productData.tr;

    return (
        <div>
            <AppHeader />
            <br />
            <br />
            <br />
            <br />
            <br />
            <br />
            {/* h5 metni dil seçimine göre gönderiliyor */}
            <ProductPage productData={localizedProductData} h5Text={h5Texts[language]} />
            <Footer />
        </div>
    );
};

export default App;
