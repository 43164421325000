// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useState } from 'react';

import { useLanguage } from '../../contexts/LanguageContext';

const Section = styled.section``;
const ContainerDiv = styled.div``;
const HeaderDiv = styled.div``;
const GridItem = styled.div``;
const Image = styled.img``;

const pageJSON = {
    header: [
        {
            title: 'Medya',
            languageCode: 'tr',
        },
        {
            title: 'Media',
            languageCode: 'en',
        }
    ],
    tabs: {
        fromUS: [
            {
                title: 'Bizden Haberler',
                languageCode: 'tr',
            },
            {
                title: 'News From Us',
                languageCode: 'en',
            }
        ],
        inThePress: [
            {
                title: 'Basında Biz',
                languageCode: 'tr',
            },
            {
                title: 'We in the Press',
                languageCode: 'en',
            }
        ]
    },
    mediaList: [
        {
            title: 'AHL Pay logosunu güncelledi',
            shortDescription: '',
            link: 'https://www.aa.com.tr/tr/sirkethaberleri/finans/ahl-pay-logosunu-guncelledi/686183',
            languageCode: 'tr',
            for: 'inThePress'
        },
        {
            title: 'AHL Pay has updated its logo',
            shortDescription: '',
            link: 'https://www.aa.com.tr/tr/sirkethaberleri/finans/ahl-pay-logosunu-guncelledi/686183',
            languageCode: 'en',
            for: 'inThePress'
        },
        {
            title: 'Sadece POS değil',
            shortDescription: '',
            link: 'https://www.youtube.com/watch?v=VA2wG1qv5P4',
            languageCode: 'tr',
            for: 'fromUS'
        },
        {
            title: 'Not just a POS',
            shortDescription: '',
            link: 'https://www.youtube.com/watch?v=VA2wG1qv5P4',
            languageCode: 'en',
            for: 'fromUS'
        },
        {
            title: 'AHL Pay bayram harçlığınızı unutmadı!',
            shortDescription: '',
            link: 'https://www.youtube.com/watch?v=Ge42If0fIRE',
            languageCode: 'tr',
            for: 'fromUS'
        },
        {
            title: "AHL Pay didn't forget your allowance!",
            shortDescription: '',
            link: 'https://www.youtube.com/watch?v=Ge42If0fIRE',
            languageCode: 'en',
            for: 'fromUS'
        },
        {
            title: "AHL Pay, 2024'te bireysel kullanıcılara daha fazla odaklanacak!",
            shortDescription: '',
            link: 'https://www.aa.com.tr/tr/isdunyasi/finans/ahl-pay-2024te-bireysel-kullanicilara-daha-fazla-odaklanacak/684861',
            languageCode: 'tr',
            for: 'inThePress'
        },
        {
            title: "AHL Pay will focus more on individual users in 2024!",
            shortDescription: '',
            link: 'https://www.aa.com.tr/tr/isdunyasi/finans/ahl-pay-2024te-bireysel-kullanicilara-daha-fazla-odaklanacak/684861',
            languageCode: 'en',
            for: 'inThePress'
        },
        {
            title: "AHL Pay'in BenimFaturam uygulaması kullanımda",
            shortDescription: '',
            link: 'https://www.aa.com.tr/tr/isdunyasi/finans/ahl-payin-benimfaturam-uygulamasi-kullanimda/683067',
            languageCode: 'tr',
            for: 'inThePress'
        },
        {
            title: "AHL Pay has launched the BenimFaturam Application!",
            shortDescription: '',
            link: 'https://www.aa.com.tr/tr/isdunyasi/finans/ahl-payin-benimfaturam-uygulamasi-kullanimda/683067',
            languageCode: 'en',
            for: 'inThePress'
        },
        {
            title: "AHL Pay Kullanıcıları IBAN’ları ile FAST’i Kullanarak Para Transferi Gerçekleştiriyor!",
            shortDescription: '',
            link: 'https://www.aa.com.tr/tr/isdunyasi/finans/ahl-pay-kullanicilari-iban-lari-ile-fast-i-kullanarak-para-transferi-gerceklestirebilecek/683562',
            languageCode: 'tr',
            for: 'inThePress'
        },
        {
            title: "AHL Pay Users Transfer Money Using FAST with Their IBANs!",
            shortDescription: '',
            link: 'https://www.aa.com.tr/tr/isdunyasi/finans/ahl-pay-kullanicilari-iban-lari-ile-fast-i-kullanarak-para-transferi-gerceklestirebilecek/683562',
            languageCode: 'en',
            for: 'inThePress'
        }
    ]
};

const _renderTabDetail = (forDetail, mediaList, language) => {

    const items = mediaList.filter(x => x.for == forDetail && x.languageCode == language);

    return items.map((data) => {
        return <div grid-item='noimg'>
            <a href={data.link} target='_blank' title=""><h3>{data.title}</h3></a>
            <a href={data.link} target='_blank' title=""><h4>{data.shortDescription}</h4></a>
        </div>
    });
}

const AboutMedia = () => {

    const { language } = useLanguage();

    const [activeCategory, setActiveCategory] = useState('fromUS');

    const childrens = <main page="inside">

        <Section element="about-media">

            <h1>{pageJSON.header.find(x => x.languageCode == language)?.title}</h1>
            <div>
                <ul tabs="">
                    <li tab-id="fromUS" onClick={() => setActiveCategory('fromUS')} active={activeCategory == 'fromUS' ? 'true' : undefined}>{pageJSON.tabs.fromUS.find(x => x.languageCode == language)?.title}</li>
                    <li tab-id="inThePress" onClick={() => setActiveCategory('inThePress')} active={activeCategory == 'inThePress' ? 'true' : undefined}>{pageJSON.tabs.inThePress.find(x => x.languageCode == language)?.title}</li>
                </ul>

                <content tabdetails="">

                    <article tab-detail="fromUS" status={activeCategory == 'fromUS' ? 'on' : 'off'}>
                        <grid grid="about-media">
                            {_renderTabDetail("fromUS", pageJSON.mediaList, language)}
                        </grid>
                    </article>

                    <article tab-detail="inThePress" status={activeCategory == 'inThePress' ? 'on' : 'off'}>
                        <grid grid="about-media">
                            {_renderTabDetail("inThePress", pageJSON.mediaList, language)}
                        </grid>
                    </article>

                </content>

            </div>
        </Section>

    </main>

    return <MainLayout children={childrens} />
};


export default AboutMedia;