
// @ts-nocheck
import styled from 'styled-components';
import MainLayout from '../layouts/MainLayout';
import { useLanguage } from '../../contexts/LanguageContext';
import { title } from 'process';
import { Helmet } from 'react-helmet';

const Section = styled.section``;
const ContainerDiv = styled.div``; 
const HeaderDiv = styled.div``;
const GridItem = styled.div``;
const Image = styled.img``;

const pageJSON = {
    headerTitle: [
        {
            title: 'Kurumsal Yönetim',
            languageCode: 'tr'
        },
        {
            title: 'Corporate Governance',
            languageCode: 'en'
        }
    ],
    ortaklık: [
        {
            title: <><h3>Ortaklık Yapısı</h3>
                <ul>
                    <h1>Ahlatcı Holding, ödenmiş sermayedeki %100’lük payı ile Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş.'nin yönetimini elinde bulunduran hâkim ortağıdır</h1>
                </ul></>,
            languageCode: 'tr'
        }
        ,
        {
            title: (
                <>
                    <h3>Partnership Structure</h3>
                    <ul>
                        <h1>Ahlatcı Holding is the controlling partner of Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş., holding 100% of the paid-in capital.</h1>
                    </ul>
                </>
            ),
            languageCode: 'en'
        }
        
    ],

    headoffice: [
        {
            title:
                <><h3>Head Office</h3><ul>
                    <li>
                        <span>Addres: </span>
                        İçerenköy Mah. Yeşilvadi Sok. No:3 Kat:8<br />
                        Ataşehir / İstanbul
                    </li>
                    <li><span>Phone Number:</span> 0216 222 1 500</li>
                    <li><span>Email: </span> <a href="mailto:destek@ahlpay.com.tr">destek@ahlpay.com.tr</a>
                    </li>
                </ul></>,
            languageCode: 'en'
        },
        {
            title: <><h3>Genel Müdürlük</h3>
                    <h2><strong>Ahlatcı Ödeme ve Elektronik Para Hizmetleri A.Ş.</strong></h2> 
                <ul>
                    <li>
                        <span>Adres: </span>
                        İçerenköy Mah. Yeşilvadi Sok. No:3 Kat:8<br />
                        Ataşehir / İstanbul
                    </li>
                    <li><span>Telefon:</span> 0216 222 1 500</li>
                    <li><span>E-posta: </span> <a href="mailto:destek@ahlpay.com.tr">destek@ahlpay.com.tr</a>
                    </li>
                </ul></>,
            languageCode: 'tr'
        }
    ],
    AhlatcıHolding: [
        {
            "title": <>
                <h3>Ahlatcı Holding</h3>
                <p>
                Ahlatcı Holding, Çorum merkezli bir şirketler topluluğu olarak, yurt dışı ve yurt içinde, 61 şirketi ve 5 binden fazla çalışanıyla, enerjiden sağlığa, kıymetli madenlerden finansa, ileri teknolojilerden otomotive, havacılıktan madenciliğe, gayrimenkul yatırımından imalat sanayiye uzanan geniş bir yelpazede, 10 farklı sektörde faaliyetlerine hız kesmeden devam etmektedir.
                </p>
                <br />
                
                    <p>
                    Birleşik Arap Emirlikleri başta olmak üzere 43 ülkeye ihracat yapan Ahlatcı Holding, ana faaliyet alanı olan kıymetli madenler sektörlerinde (kuyumculuk ve altın) Türkiye iç pazarının en büyük tedarikçilerinden biridir. Türkiye’nin altın üretimi ve ihracatında son 5 yılda Fortune 500 Türkiye sıralamasında Ahlatcı Kuyumculuk İlk 5’te, Ahlatcı Metal Rafinerisi ise ilk 30’da yer almaktadır.
                    </p>
                    <br />
                    <li>
                   <span>Daha fazla bilgi için </span>
                   <a href="https://ahlatci.com.tr">ahlatci.com.tr</a><span>ziyaret ediniz.</span>
                    </li>
            </>,
            "languageCode": "tr"
        },
        {
            "title": <>
                <h3>Ahlatcı Holding</h3>
                <p>
                    Ahlatcı Holding, based in Çorum, Turkey, is a conglomerate operating in both domestic and international markets
                    with 61 companies and over 5,000 employees. The group is active across a broad spectrum of 10 sectors,
                    ranging from energy to healthcare, precious metals to finance, advanced technologies to automotive,
                    aviation to mining, real estate investment to manufacturing industries.
                </p>
                <ul>
                    <li>
                        <span>Exports to 43 countries, including the United Arab Emirates.</span>
                    </li>
                    <li>
                        <span>
                            A major supplier in Turkey’s domestic market in its core sectors of precious metals
                            (jewelry and gold).
                        </span>
                    </li>
                    <li>
                        <span>
                            In the past 5 years, in Fortune 500 Turkey rankings for gold production and export:
                            <ul>
                                <li>Ahlatcı Jewelry: Ranked in the Top 5</li>
                                <li>Ahlatcı Metal Refinery: Ranked in the Top 30</li>
                            </ul>
                        </span>
                    </li>
                    <li>
                        <span>Website: </span>
                        <a href="https://ahlatci.com.tr">ahlatci.com.tr</a>
                    </li>
                </ul>
            </>,
            "languageCode": "en"
        }
    ]
,    

    Branch: [
        {
            title:
                <><h3>Branch</h3><ul>
                    <li>
                        <span>Addres: Fulya Mah. Büyükdere Cad. No:40/2 </span>
                        Rumeli İş Hanı <br />
                        Şişli - İstanbul
                    </li>
                    <li><span>Phone Number: 444 90 66</span> </li>
                    <li><span>Email: </span><a href="mailto:destek@ahlpay.com.tr">destek@ahlpay.com.tr</a>
                    </li>
                </ul></>,
            languageCode: 'en'
        },

        {
            title: <><h3>Şube</h3><ul>
                <li>
                    <span>Adres: Fulya Mah. Büyükdere Cad. No:40/2 </span>
                    Rumeli İş Hanı <br />
                    Şişli - İstanbul
                </li>
                <li><span>Telefon:</span> 444 90 66</li>
                <li><span>E-posta: </span> <a href="mailto:destek@ahlpay.com.tr">destek@ahlpay.com.tr</a>
                </li>
            </ul></>,
            languageCode: 'tr'
        }
    ],
    BranchCorum: [
        {
            title:
                <><h3>Center</h3><ul>
                    <li>
                        <span>Addres: Eğridere Caddesi No:17 </span>
                        <br />
                        Merkez / Çorum
                    </li>
                    <li><span>Phone Number:</span> 444 0 186</li>
                    <li><span>Email: </span> <a href="mailto:kurumsal@ahlatci.com.tr">kurumsal@ahlatci.com.tr</a>
                    </li>
                </ul></>,
            languageCode: 'en'
        },
        {
            title: <><h3>Merkez</h3><ul>
                <li>
                    <span>Adres: Eğridere Caddesi No:17 </span>
                    <br />
                    Merkez / Çorum
                </li>
                <li><span>Telefon: 444 0 186</span> </li>
                <li><span>E-posta: </span> <a href="mailto:kurumsal@ahlatci.com.tr">kurumsal@ahlatci.com.tr</a>
                </li>
            </ul></>,
            languageCode: 'tr'
        }
    ],
    corpoateInformation: [
        {
            description: <><h3>Kurumsal Bilgiler</h3>
                <ul>
                    <li><span>Ticari Sicil Numarası:</span> 384098-5</li>
                    <li><span>Mersis Numarası:</span> 0010-1392-1610-0001</li>
                    <li>
                        <span>Denetleyici Merci: </span>
                        Türkiye Cumhuriyet Merkez Bankası (TCMB)<br />
                        Hacı Bayram Mah. İstiklal Cad. No:10 PK:06050<br />
                        Ulus - Altındağ / Ankara
                    </li>
                    <li><span>Telefon:</span> 0 (312) 507 50 00</li>
                    <li><span>E-posta: </span> <a href="mailto:kurumsal@ahlatci.com.tr">kurumsal@ahlatci.com.tr</a>
                    </li>
                    <li>Kep Adresi :ahlatciodeme@hs03.kep.tr</li>
                </ul></>,
            languageCode: 'tr'
        },
        {
            description: <><h3>Corporate Information</h3>
                <ul>
                    <li><span>Commercial Registry Number:</span> 384098-5</li>
                    <li><span>MERSIS Number (Central Registration System Number):</span> 0010-1392-1610-0001</li>
                    <li>
                        <span>Supervisory Authority: </span>
                        Türkiye Cumhuriyet Merkez Bankası (TCMB)<br />
                        Hacı Bayram Mah. İstiklal Cad. No:10 PK:06050<br />
                        Ulus - Altındağ / Ankara
                    </li>
                    <li><span>Phone Number:</span> 0 (312) 507 50 00</li>
                </ul></>,
            languageCode: 'en'
        },
    ],
    FaalRapor: [
        {
            title: (
                <>
                    <h3>Annual Report</h3>
                    <ul>
                        <li>
                        <ul>
                          <li><a href="/assets/legals/AHLPay_Frapor21.pdf" download="AHLPay_Frapor21.pdf">2021 Annual Report</a></li>
                          <li><a href="/assets/legals/AHLPay_Frapor22.pdf" download="AHLPay_Frapor22.pdf">2022 Annual Report</a></li>
                          <li><a href="/assets/legals/AHLPay_Frapor23.pdf" download="AHLPay_Frapor23.pdf">2023 Annual Report</a></li>
                        </ul>

                        </li>
                    </ul>
                </>
            ),
            languageCode: 'en'
        },
        {
            title: (
                <>
                    <h3>Faaliyet Raporu</h3>
                     <ul>
                        <li>
                            <ul>
                                <li><a href="/assets/legals/AHLPay_Frapor21.pdf" download="AHLPay_Frapor21.pdf">2021 Yılı Faaliyet Raporu</a></li>
                                <li><a href="/assets/legals/AHLPay_Frapor22.pdf" download="AHLPay_Frapor22.pdf">2022 Yılı Faaliyet Raporu</a></li>
                                <li><a href="/assets/legals/AhlPay_Frapor23.pdf" download="AhlPay_Frapor23.pdf">2023 Yılı Faaliyet Raporu</a></li>
                            </ul>
                        </li>
                    </ul>
                </>
            ),
            languageCode: 'tr'
        }
    ],
}
const HeaderTitle = styled.h1`
  font-size: 36px;
  margin-bottom: 20px;
  color: #6c757d; 
   
`;
const TabsContainer = styled.ul`
  display: flex;
  padding: 0;
 
  list-style-type: none;
`;

const TabItem = styled.li`
  list-style: none;
  padding: 10px 20px;
  font-size: 16px;
  border: 3px solid transparent;

  a {
    text-decoration: none;
    color: #6c757d;
    border: 3px solid transparent;
  }

  &.active {
    background: linear-gradient(180deg, rgba(var(--color-white)) 0%, rgba(var(--color-grey9)) 100%);
  }
`;

const GeneralInfos = () => {
  const { language } = useLanguage();
  const childrens = (
    <main page="inside">
      <Helmet><title>Kurumsal Yönetim</title></Helmet>
      <section element="pageheader"></section>

      <section element="contact">
        <div>
        <section className="element-faq-general-directors" element="faq">
            <div>
            <HeaderTitle>
          {pageJSON.headerTitle.find((x) => x.languageCode === language)?.title}
        </HeaderTitle>
              <br /><br /><br />
        <TabsContainer>
       <TabItem tab-id="tab-1" className="active">
       <a href="/YonetimBilgi">{language === 'tr' ? 'Genel Bilgiler' : 'General Information'}</a>
       </TabItem>
        <TabItem tab-id="tab-2">
       <a href="/UstYonetim">{language === 'tr' ? 'Üst Yönetim' : 'Management Team'}</a>
       </TabItem>
       <TabItem tab-id="tab-3">
       <a href="/YonetimKurulu">{language === 'tr' ? 'Yönetim Kurulu' : 'Board of Directors'}</a>
       </TabItem>
       </TabsContainer>
          </div>
          </section>
          <grid grid="contact" grid-rows="2x">
            <GridItem grid-item="">
              {pageJSON.headoffice.find((x) => x.languageCode === language)?.title}
            </GridItem>
            <GridItem grid-item="">
              {pageJSON.BranchCorum.find((x) => x.languageCode === language)?.title}
            </GridItem>
          </grid>
          <hr></hr>
          <grid grid="contact" grid-rows="2x">
            <GridItem grid-item="">
              {pageJSON.Branch.find((x) => x.languageCode === language)?.title}
            </GridItem>
            <GridItem grid-item="">
              {pageJSON.FaalRapor.find((x) => x.languageCode === language)?.title}
            </GridItem>
          </grid>
          <hr />
          <grid grid="contact" grid-rows="2x">
            <div grid-item="">
              {pageJSON.corpoateInformation.find((x) => x.languageCode === language)?.description}
            </div>
            <div grid-item="">
              {pageJSON.ortaklık.find((x) => x.languageCode === language)?.title}
              <br /><br />
              {pageJSON.AhlatcıHolding.find((x) => x.languageCode === language)?.title}
            </div>
          </grid>
        </div>
      </section>
    </main>
  );

  return <MainLayout children={childrens} />;
};

export default GeneralInfos;

